import React, { useEffect, useState } from "react";
import { Table, Input, InputNumber, Popconfirm, Form, Button, Checkbox } from "antd";
import api from "../../services/Api";

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = React.useRef(null);
    const form = React.useContext(EditableContext);

    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log("Save failed:", errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
                className={dataIndex === "description" ? "w-40" : "w-20"}
            >
                {dataIndex === "area" ||
                dataIndex === "bedrooms" ||
                dataIndex === "beds" ||
                dataIndex === "extrabeds" ? (
                    <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} />
                ) : (
                    <Input ref={inputRef} onPressEnter={save} onBlur={save} />
                )}
            </Form.Item>
        ) : (
            <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

const PropertyApartmentList = ({ propertyId }) => {
    const [apartments, setApartments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [newApartment, setNewApartment] = useState({
        roomNumber: "",
        area: null,
        bedrooms: null,
        hasLoft: false,
        hasBalcony: false,
        hasSauna: false,
        hasTerrace: false,
        description: "",
        beds: null,
        extrabeds: null,
    });

    useEffect(() => {
        const fetchApartments = async () => {
            setLoading(true);
            try {
                const response = await api.getApartments(propertyId);
                setApartments(response);
            } catch (error) {
                console.error("Failed to fetch apartments", error);
            } finally {
                setLoading(false);
            }
        };

        if (propertyId) {
            fetchApartments();
        }
    }, [propertyId]);

    const handleSave = async (row) => {
        try {
            // Call API to update the apartment data
            await api.updateApartment(row.id, row);
            const newData = [...apartments];
            const index = newData.findIndex((item) => row.id === item.id);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setApartments(newData);
            }
        } catch (error) {
            console.error("Failed to save apartment data:", error);
        }
    };

    const yesIcon = (onClick) => (
        <span className="text-green-600 cursor-pointer" onClick={onClick}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
            </svg>
        </span>
    );

    const noIcon = (onClick) => (
        <span className="text-red-600 cursor-pointer" onClick={onClick}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
            </svg>
        </span>
    );

    const columns = [
        {
            title: "Huoneisto",
            dataIndex: "roomNumber",
            editable: true,
            key: "roomNumber",
        },
        {
            title: "Area (m²)",
            dataIndex: "area",
            editable: true,
            key: "koko",
        },
        {
            title: "Mh",
            dataIndex: "bedrooms",
            editable: true,
            key: "bedrooms",
        },
        {
            title: "Parvi",
            dataIndex: "hasLoft",
            key: "hasLoft",
            render: (hasLoft, record) =>
                hasLoft
                    ? yesIcon(() => handleToggle(record, "hasLoft"))
                    : noIcon(() => handleToggle(record, "hasLoft")),
        },
        {
            title: "Parveke",
            dataIndex: "hasBalcony",
            key: "hasBalcony",
            render: (hasBalcony, record) =>
                hasBalcony
                    ? yesIcon(() => handleToggle(record, "hasBalcony"))
                    : noIcon(() => handleToggle(record, "hasBalcony")),
        },
        {
            title: "Terassi",
            dataIndex: "hasTerrace",
            key: "hasTerrace",
            render: (hasTerrace, record) =>
                hasTerrace
                    ? yesIcon(() => handleToggle(record, "hasTerrace"))
                    : noIcon(() => handleToggle(record, "hasTerrace")),
        },
        {
            title: "Sauna",
            dataIndex: "hasSauna",
            key: "hasSauna",
            render: (hasSauna, record) =>
                hasSauna
                    ? yesIcon(() => handleToggle(record, "hasSauna"))
                    : noIcon(() => handleToggle(record, "hasSauna")),
        },
        {
            title: "Sängyt",
            dataIndex: "beds",
            editable: true,
            key: "beds",
        },
        {
            title: "Lisäsängyt",
            dataIndex: "extrabeds",
            editable: true,
            key: "extrabeds",
        },
        {
            title: "Kuvaus",
            dataIndex: "description",
            editable: true,
            key: "description",
        },
        {
            title: "",
            dataIndex: "operation",
            width: 30,
            render: (_, record) =>
                apartments.length >= 1 ? (
                    <div className="text-blue-600">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="size-6 cursor-pointer"
                            onClick={() => handleCloneApartment(record)}
                        >
                            <path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 0 1 3.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0 1 21 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 0 1 7.5 16.125V3.375Z" />
                            <path d="M15 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 17.25 7.5h-1.875A.375.375 0 0 1 15 7.125V5.25ZM4.875 6H6v10.125A3.375 3.375 0 0 0 9.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V7.875C3 6.839 3.84 6 4.875 6Z" />
                        </svg>
                    </div>
                ) : null,
        },
        {
            title: "",
            dataIndex: "operation",
            width: 30,
            render: (_, record) =>
                apartments.length >= 1 ? (
                    <Popconfirm
                        title="Sure to delete?"
                        onConfirm={() => handleDelete(record.id)}
                        className="text-red-600 cursor-pointer"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-6 cursor-pointer"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                            />
                        </svg>
                    </Popconfirm>
                ) : null,
        },
    ];

    const handleToggle = async (record, field) => {
        const newValue = !record[field];
        const updatedRecord = { ...record, [field]: newValue };

        try {
            await api.updateApartment(record.id, updatedRecord);
            setApartments((prevApartments) =>
                prevApartments.map((apartment) => (apartment.id === record.id ? updatedRecord : apartment))
            );
        } catch (error) {
            console.error(`Failed to update ${field} for apartment ${record.id}`, error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await api.deleteApartment(id);
            setApartments(apartments.filter((item) => item.id !== id));
        } catch (error) {
            console.error("Failed to delete apartment:", error);
        }
    };

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });

    const handleCancelAddApartment = () => {
        setIsAdding(false)

        setNewApartment({
            roomNumber: "",
            area: null,
            bedrooms: null,
            hasLoft: false,
            hasBalcony: false,
            hasSauna: false,
            hasTerrace: false,
            description: "",
            beds: null,
            extrabeds: null,
        });
    };

    // Function to handle adding the new apartment
    const handleAddApartment = async () => {
        try {
            const addedApartment = await api.addApartment(propertyId, newApartment);
            setApartments([...apartments, addedApartment]);
            setNewApartment({
                roomNumber: "",
                area: null,
                bedrooms: null,
                hasLoft: false,
                hasBalcony: false,
                hasSauna: false,
                hasTerrace: false,
                description: "",
                beds: null,
                extrabeds: null,
            });
            setIsAdding(false);
        } catch (error) {
            console.error("Failed to add apartment:", error);
        }
    };

    const handleCloneApartment = (apartment) => {
        const clonedApartment = { ...apartment, id: undefined }; // Remove the id to avoid conflicts
        setNewApartment(clonedApartment);
        setIsAdding(true);
    };

    const generateDescription = (newApartment) => {
        const { bedrooms, hasLoft, hasBalcony, hasSauna, hasTerrace } = newApartment;
        let description = `${bedrooms + 1}h+k`;

        if (hasSauna) {
            description += "+s";
        }

        if (hasLoft) {
            description += "+parvi";
        }

        if (hasBalcony) {
            description += "+parveke";
        }

        if (hasTerrace) {
            description += "+terassi";
        }

        return description;
    };

    useEffect(() => {
        const description = generateDescription(newApartment);

        if (newApartment.bedrooms !== null) {
            setNewApartment((prevApartment) => ({
                ...prevApartment,
                description,
            }));
        }
    }, [newApartment]);

    return (
        <>
            <Table
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                columns={mergedColumns}
                dataSource={apartments}
                loading={loading}
                rowKey={(record) => record.id}
                pagination={false}
                bordered
                size="small"
                className="select-none"
            />
            {isAdding ? (
                <Form layout="inline" className="mt-5 w-full select-none">
                    <Form.Item className="w-20" label="Huoneisto">
                        <Input
                            placeholder="Huoneisto"
                            value={newApartment.roomNumber}
                            onChange={(e) => setNewApartment({ ...newApartment, roomNumber: e.target.value })}
                        />
                    </Form.Item>
                    <Form.Item className="w-20" label="Koko (m²)">
                        <InputNumber
                            placeholder="Koko (m²)"
                            value={newApartment.area}
                            onChange={(value) => setNewApartment({ ...newApartment, area: value })}
                        />
                    </Form.Item>
                    <Form.Item className="w-20" label="Mh">
                        <InputNumber
                            placeholder="Mh"
                            value={newApartment.bedrooms}
                            onChange={(value) => setNewApartment({ ...newApartment, bedrooms: value })}
                        />
                    </Form.Item>
                    <Form.Item label="Parvi">
                        <Checkbox
                            checked={newApartment.hasLoft}
                            onChange={(e) => setNewApartment({ ...newApartment, hasLoft: e.target.checked })}
                            className="font-light text-xs"
                        >Parvi
                        </Checkbox>
                    </Form.Item>
                    <Form.Item label="Parveke">
                        <Checkbox
                            checked={newApartment.hasBalcony}
                            onChange={(e) => setNewApartment({ ...newApartment, hasBalcony: e.target.checked })}
                            className="font-light text-xs"
                        >Parveke
                        </Checkbox>
                    </Form.Item>
                    <Form.Item label="Terassi">
                        <Checkbox
                            checked={newApartment.hasTerrace}
                            onChange={(e) => setNewApartment({ ...newApartment, hasTerrace: e.target.checked })}
                            className="font-light text-xs"
                        >Terassi
                        </Checkbox>
                    </Form.Item>
                    <Form.Item label="Sauna">
                        <Checkbox
                            checked={newApartment.hasSauna}
                            onChange={(e) => setNewApartment({ ...newApartment, hasSauna: e.target.checked })}
                            className="font-light text-xs"
                        >Sauna
                        </Checkbox>
                    </Form.Item>
                    <Form.Item label="Sängyt" className="w-20">
                        <InputNumber
                            placeholder="Sängyt"
                            value={newApartment.beds}
                            onChange={(value) => setNewApartment({ ...newApartment, beds: value })}
                        />
                    </Form.Item>
                    <Form.Item label="Lisäsängyt" className="w-20">
                        <InputNumber
                            placeholder="Lisäsängyt"
                            value={newApartment.extrabeds}
                            onChange={(value) => setNewApartment({ ...newApartment, extrabeds: value })}
                        />
                    </Form.Item>
                    <Form.Item className="flex flex-col justify-end">
                        <Button type="default" onClick={() => handleCancelAddApartment()} style={{ marginLeft: 8 }}>
                            Peruuta
                        </Button>
                        <Button type="primary" onClick={handleAddApartment}>
                            Lisää
                        </Button>
                    </Form.Item>
                </Form>
            ) : (
                <Button type="primary" onClick={() => setIsAdding(true)} style={{ marginTop: 20 }}>
                    Lisää Huoneisto
                </Button>
            )}
        </>
    );
};

export default PropertyApartmentList;
