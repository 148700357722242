import React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Breadcrumbs from "../common/Breadcrumbs";

const MainLayout = ({ children }) => {
    return (
        <div className="flex flex-col h-dvh">
            <Header pageType={1} />
            <div className=" px-5 2xl:px-0">
                <Breadcrumbs />
                <main className="flex-grow">{children}</main>
            </div>
            <Footer />
        </div>
    );
};

export default MainLayout;
