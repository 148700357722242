import React, { useState, useEffect } from "react";
import { Button, Image, Form, Select, Upload } from "antd";
import { CameraOutlined, UploadOutlined } from "@ant-design/icons";
import SortableList, { SortableItem } from "react-easy-sort";

import PropertySelectModal from "../../modals/PropertySelectModal";
import GalleryModal from "../../modals/GalleryModal";

import arrayMove from "array-move";
import api from "../../../services/Api";
import CollapsibleHtmlContent from "../../common/CollapsibleHtmlContent";
import InfoBar from "../../common/InfoBar";
import Dragger from "antd/es/upload/Dragger";
import AuctionFormApartment from "./AuctionFormApartment";
import ImageThumbnail from "../../common/ImageThumbnail";

const AuctionFormProperty = ({ auction, setAuction }) => {
    const [propertyModalOpen, setPropertyModalOpen] = useState(false);
    const [areas, setAreas] = useState([]);
    const [selectedArea, setSelectedArea] = useState(null);
    const [galleryModalOpen, setGalleryModalOpen] = useState(false);

    useEffect(() => {
        fetchAreas();
    }, []);

    const fetchAreas = async () => {
        try {
            const response = await api.getAvailableAreas();
            setAreas(response);
        } catch (error) {
            console.error("Error fetching areas:", error);
        }
    };

    const handlePropertySelect = (property) => {
        setAuction((prevAuction) => ({
            ...prevAuction,
            propertyId: property.id,
            property: property,
            images: [],
        }));
    };

    const onSortEnd = (oldIndex, newIndex) => {
        setAuction((prevAuction) => {
            const newImages = arrayMove([...prevAuction.images], oldIndex, newIndex);
            return {
                ...prevAuction,
                images: newImages,
            };
        });
    };

    useEffect(() => {
        auction.images.forEach((image, index) => {
            image.sort_num = index + 1;
        });
    }, [auction.images]);

    const handleAreaChange = (value) => {
        setSelectedArea(value);
        setPropertyModalOpen(true);
    };

    const uploadProps = {
        name: "file",
        customRequest: async ({ file, onSuccess, onError }) => {
            const params = {
                propertyId: auction.property.id,
                auctionId: auction.uuid,
            };

            try {
                const response = await api.uploadFile(file, params);
                onSuccess(response);

                setAuction((prevAuction) => ({
                    ...prevAuction,
                    images: response.images,
                }));
            } catch (error) {
                onError(error);
            }
        },
        onChange(info) {
            if (info.file.status !== "uploading") {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === "done") {
            } else if (info.file.status === "error") {
                console.log(`${info.file.name} file upload failed.`);
            }
        },
    };

    const handleRemovePhotoFromAuction = (e) => {
        e.stopPropagation();

        const imageId = parseInt(e.currentTarget.getAttribute("data-image-id"));
        const updatedImages = auction.images.filter((image) => image.id !== imageId);

        setAuction((prevAuction) => ({
            ...prevAuction,
            images: updatedImages,
        }));
    };

    return (
        <>
            <div className="text-xl font-bold mb-2">Kohteen tiedot</div>
            {!auction.property && (
                <InfoBar>Valitse alue, jolla kohteesi sijaitsee ja sen jälkeen valitse kohde listasta.</InfoBar>
            )}

            <Select
                value={selectedArea}
                onChange={handleAreaChange}
                placeholder={<div className="text-gray-500">Valitse sijainti</div>}
                className="w-32 mb-3"
            >
                {areas.map((area) => (
                    <Select.Option key={area} value={area}>
                        {area}
                    </Select.Option>
                ))}
            </Select>

            <Form.Item name="property" rules={[{ required: true, message: "Kohde on pakollinen" }]}>
                {auction.property && (
                    <div className="mt-5">
                        <div className="bg-white flex gap-2 mb-5">
                            <div className="p-3">
                                <div className="text-xl font-bold mb-2">{auction.property.title}</div>
                                <div className="text-base text-gray-700 mb-2">
                                    <strong>Omistusmuoto</strong>
                                    <br />
                                    {auction.property.ownershipType === "week"
                                        ? "viikko-osake"
                                        : auction.property.ownershipType + " osaomistus"}
                                </div>
                                <div className="text-base text-gray-700 mb-2">
                                    <strong>Kohteen osoite</strong>
                                    <br />
                                    {auction.property.address}, {auction.property.zipcode} {auction.property.city}
                                </div>
                                {auction.property.serviceAddress && (
                                    <div className="text-base text-gray-700">
                                        <strong>Avainpalvelun osoite</strong>
                                        <br />
                                        {auction.property.serviceAddress}, {auction.property.serviceZipcode}{" "}
                                        {auction.property.serviceCity}
                                    </div>
                                )}
                                {auction.property.description && (
                                    <>
                                        <strong>Kohteen kuvaus</strong>
                                        <CollapsibleHtmlContent htmlContent={auction.property.description} />
                                    </>
                                )}
                            </div>
                            <div className="p-3">
                                {auction.images && auction.images[0] ? (
                                    <Image width={300} height={200} src={auction.images[0]?.urls.medium} />
                                ) : (
                                    <div
                                        style={{ width: "300px", height: "200px" }}
                                        className="bg-gray-50 flex justify-center items-center"
                                    >
                                        <CameraOutlined className="text-gray-400" style={{ fontSize: "50px" }} />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            <Button type="primary" onClick={() => setGalleryModalOpen(true)} className="mb-5 mr-5">
                                Valitse ilmoituksen kuvat
                            </Button>
                            <InfoBar>
                                Voit myös ladata kuvia ilmoitukselle pudottamalla niitä alla olevalle alueelle.
                            </InfoBar>
                            <Dragger {...uploadProps} multiple={true} accept="image/*" showUploadList={false}>
                                <SortableList
                                    onSortEnd={onSortEnd}
                                    className="list flex flex-wrap gap-5 mt-5 user-select-none"
                                    draggedItemClassName="dragged"
                                >
                                    {auction.images.length ? (
                                        auction.images?.map((image) => (
                                            <SortableItem key={image.id}>
                                                <ImageThumbnail
                                                    image={image}
                                                    handleRemovePhotoFromAuction={handleRemovePhotoFromAuction}
                                                />
                                            </SortableItem>
                                        ))
                                    ) : (
                                        <>
                                            <p className="ant-upload-drag-icon">
                                                <UploadOutlined />
                                            </p>
                                            <p className="ant-upload-text">Voit siirtää yhden tai useampia kuvia</p>
                                        </>
                                    )}
                                </SortableList>
                            </Dragger>
                        </div>
                        <AuctionFormApartment auction={auction} setAuction={setAuction} />
                    </div>
                )}
            </Form.Item>

            <PropertySelectModal
                open={propertyModalOpen}
                onClose={() => setPropertyModalOpen(false)}
                onSelectProperty={handlePropertySelect}
                selectedArea={selectedArea}
                onAreaChange={setSelectedArea}
            />

            <GalleryModal
                open={galleryModalOpen}
                onClose={() => setGalleryModalOpen(false)}
                propertyId={auction.property?.id}
                onSelectImages={(images) =>
                    setAuction((prevAuction) => ({
                        ...prevAuction,
                        images: images,
                    }))
                }
                selectedImages={auction.images}
            />
        </>
    );
};

export default AuctionFormProperty;
