import React, { useCallback, useEffect } from "react";
import { Select, Button, InputNumber, Spin, Checkbox, Space } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

const AuctionFilters = React.memo(({ filters, onFilterChange, areas, loadingAreas, areasError }) => {
    const handleInputChange = useCallback(
        (field, value) => {
            if (field === "beds") {
                if (value < 2 && value !== null) value = 2;
                if (value > 8) value = 8;
            }

            onFilterChange({
                ...filters,
                [field]: value,
            });
        },
        [filters, onFilterChange]
    );

    const debounce = (func, delay) => {
        let timer;

        return function (...args) {
            const context = this;
            clearTimeout(timer);
            timer = setTimeout(() => func.apply(context, args), delay);
        };
    };    

    const debouncedHandleInputChange = debounce(handleInputChange, 750);

    const handleReset = useCallback(() => {
        onFilterChange({
            types: ["sale", "rent", "auction"],
            areas: [],
            propertyWeeks: [],
            beds: null,
            priceMax: null,
            attributes: [],
        });
    }, [onFilterChange]);

    const typeOptions = [
        { label: "Myytävät", value: "sale" },
        { label: "Vuokrattavat", value: "rent" },
        { label: "Huutokaupat", value: "auction" },
    ];

    const propertyWeekOptions = [
        { label: "2-7", value: "2-7" },
        { label: "8-18", value: "8-18" },
        { label: "19-32", value: "19-32" },
        { label: "33-51", value: "33-51" },
        { label: "52-1", value: "52-1" },
    ];

    const attributesOptions = [
        { label: "Sauna", value: "hasSauna" },
        { label: "Parveke / Terassi", value: "hasBalcony" },
    ];

    useEffect(() => {
        if (filters.types.includes("auction") && filters.priceMax !== null) {
            handleInputChange("priceMax", null);
        }
    }, [filters.types, filters.priceMax, handleInputChange]);

    return (
        <div className="flex flex-wrap gap-5 select-none">
            {/* Type Selector */}
            <div className="w-40">
                <div className="font-bold mb-2">Ilmoitukset</div>
                <Checkbox.Group
                    value={filters.types}
                    onChange={(value) => handleInputChange("types", value)}
                    className="w-full"
                >
                    <Space direction="vertical">
                        {typeOptions.map((option) => (
                            <div key={option.value}>
                                <Checkbox value={option.value} className="mr-2">
                                    <span className="font-normal cursor-pointer hover:font-semibold">
                                        {option.label}
                                    </span>
                                </Checkbox>
                            </div>
                        ))}
                    </Space>
                </Checkbox.Group>
            </div>

            {/* Area Selector */}
            <div className="w-40">
                <div className="font-bold mb-2">Sijainti</div>
                {loadingAreas ? (
                    <Spin />
                ) : areasError ? (
                    <div className="text-red-500">{areasError}</div>
                ) : (
                    <Select
                        mode="multiple"
                        value={filters.areas}
                        onChange={(value) => handleInputChange("areas", value)}
                        allowClear
                        className="w-full h-8"
                        maxTagCount={"responsive"}
                    >
                        {areas?.map((area) => (
                            <Option key={area.area} value={area.area} disabled={area.auctions === 0}>
                                {area.area}
                            </Option>
                        ))}
                    </Select>
                )}
            </div>

            {/* Property Week Selector */}
            <div className="w-40">
                <div className="font-bold mb-2">Viikko</div>
                <Checkbox.Group
                    value={filters.propertyWeeks}
                    onChange={(value) => handleInputChange("propertyWeeks", value)}
                    className="w-full"
                >
                    <div className="grid grid-flow-col grid-rows-3">
                        {propertyWeekOptions.map((option) => (
                            <div key={option.value}>
                                <Checkbox value={option.value} className="mr-2">
                                    <span className="font-normal cursor-pointer hover:font-semibold">
                                        {option.label}
                                    </span>
                                </Checkbox>
                            </div>
                        ))}
                    </div>
                </Checkbox.Group>
            </div>

            {/* Bed Selector */}
            <div className="w-40 select-none relative">
                <div className="font-bold mb-2">Vuodepaikat min</div>
                <div className="flex items-center">
                    <Button
                        icon={<MinusOutlined />}
                        onClick={() => handleInputChange("beds", (filters.beds || 2) - 1)}
                        disabled={(filters.beds || 2) <= 2}
                        className="size-8"
                    />
                    <div className="w-12 text-center">{filters.beds || "2"}</div>
                    <Button
                        icon={<PlusOutlined />}
                        onClick={() => handleInputChange("beds", (filters.beds || 2) + 1)}
                        disabled={(filters.beds || 2) >= 8}
                        className="size-8"
                    />
                </div>
            </div>

            {/* Attributes Selector */}
            <div className="w-40">
                <div className="font-bold mb-2">Ominaisuudet</div>
                <Checkbox.Group
                    value={filters.attributes}
                    onChange={(value) => handleInputChange("attributes", value)}
                    className="w-full"
                >
                    <Space direction="vertical">
                        {attributesOptions.map((option) => (
                            <div key={option.value}>
                                <Checkbox value={option.value} className="mr-2">
                                    <span className="font-normal cursor-pointer hover:font-semibold">
                                        {option.label}
                                    </span>
                                </Checkbox>
                            </div>
                        ))}
                    </Space>
                </Checkbox.Group>
            </div>

            {/* Conditionally Render Price Max Input */}
            {!filters.types.includes("auction") && (
                <div className="w-40">
                    <div className="font-bold mb-2">Hinta max</div>
                    <InputNumber
                        min={0}
                        max={1000000}
                        value={filters.priceMax}
                        onChange={(value) => debouncedHandleInputChange("priceMax", value)}
                        className="w-full"
                        size="large"
                        placeholder="Hinta max"
                    />
                </div>
            )}

            {/* Apply and Reset Buttons */}
            <div className="flex items-end justify-end flex-grow">
                <Button onClick={handleReset} className="">
                    Tyhjennä
                </Button>
            </div>
        </div>
    );
});

export default AuctionFilters;
