import React from "react";
import { Tooltip } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const ImageThumbnail = ({ image, handleRemovePhotoFromAuction }) => {
    return (
        <div
            style={{
                width: "175px",
                height: "125px",
                overflow: "hidden",
                border: "solid 1px #ddd",
                userSelect: "none",
                cursor: "grab",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundImage: `url(${image.urls.thumbnail})`,
                position: "relative",
            }}
        >
            <div>
                <Tooltip title="Poista kuva">
                    <div
                        onClick={handleRemovePhotoFromAuction}
                        data-image-id={image.id}
                        className="absolute size-6 flex items-center justify-center top-1 right-1 bg-red-600 text-white rounded cursor-pointer"
                    >
                        <CloseOutlined />
                    </div>
                </Tooltip>
            </div>
        </div>
    );
};

export default ImageThumbnail;
