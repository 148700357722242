import React from "react";

const ChatList = ({ chats, fetchMessages }) => {
    if (chats.length === 0) {
        return <div>Ei viestejä</div>;
    }

    return (
        <>
            {chats.map((chat) => (
                <div
                    key={chat.uuid}
                    className="border-b border-gray-300 cursor-pointer"
                    onClick={fetchMessages}
                    data-id={chat.uuid}
                >
                    <div className="">
                        {new Date(chat.messages[chat.messages.length - 1]?.createdAt.date).toLocaleDateString()}
                    </div>
                    <h4 className="font-bold">{chat.propertyTitle}</h4>
                    <div className="">{chat.messages[chat.messages.length - 1]?.content}</div>
                </div>
            ))}
        </>
    );
};

export default ChatList;
