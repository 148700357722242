import React from "react";
import { Link } from "react-router-dom";

const AuctionMiniCard = ({ auction }) => {
    const auctionTypes = {
        auction: "Huutokauppa",
        sale: "Myynti",
        rent: "Vuokraus",
    }

    return (
        <Link key={auction.number} to={`/kohde/${auction.number}/${auction.slug}`}>
            <div className="rounded border-gray-300 bg-white border-b-4 border-b-primary flex flex-col shadow-lg hover:shadow-xl relative overflow-hidden transition-all transition-duration-200 hover:scale-105">
                <div className="absolute w-full text-center top-0 left-0 bg-primary bg-opacity-50 text-white px-2 py-1 text-xs font-bold">
                    {auctionTypes[auction.type]}
                </div>
                <div>
                    {auction.images?.length ? (
                        <div
                            className="w-full rounded-t-md"
                            style={{
                                backgroundImage: `url(${auction.images[0].urls.medium})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                height: "100px",
                                width: "auto",
                            }}
                        ></div>
                    ) : (
                        <div
                            className="flex items-center justify-center bg-gray-300 text-gray-500 rounded-t-md"
                            style={{
                                height: "100px",
                                width: "100%",
                                display: "flex",
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="size-20"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z"
                                />
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z"
                                />
                            </svg>
                        </div>
                    )}
                </div>
                <div className="flex flex-col h-full p-4">
                    <div className="flex-1">
                        <div className="text-black">
                            <h3 className="text-sm font-medium text-primary mb-2 truncate">{auction.title}</h3>
                        </div>
                    </div>
                    <div id="price" className="flex justify-between text-black">
                        {auction.type === "auction" && (
                            <>{auction.bids?.length > 0 ? `${auction.bids[0].amount} €` : `${auction.startPrice} €`}</>
                        )}
                        {auction.type === "sale" && `${auction.startPrice} €`}
                        {auction.type === "rent" && `${auction.startPrice} € / vko`}
                        {auction?.propertyWeek > 0 && (
                            <div className="flex items-center">
                                <div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        className="icon icon-tabler icons-tabler-filled icon-tabler-calendar text-primary mr-2"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M16 2a1 1 0 0 1 .993 .883l.007 .117v1h1a3 3 0 0 1 2.995 2.824l.005 .176v12a3 3 0 0 1 -2.824 2.995l-.176 .005h-12a3 3 0 0 1 -2.995 -2.824l-.005 -.176v-12a3 3 0 0 1 2.824 -2.995l.176 -.005h1v-1a1 1 0 0 1 1.993 -.117l.007 .117v1h6v-1a1 1 0 0 1 1 -1zm3 7h-14v9.625c0 .705 .386 1.286 .883 1.366l.117 .009h12c.513 0 .936 -.53 .993 -1.215l.007 -.16v-9.625z" />
                                        <path d="M12 12a1 1 0 0 1 .993 .883l.007 .117v3a1 1 0 0 1 -1.993 .117l-.007 -.117v-2a1 1 0 0 1 -.117 -1.993l.117 -.007h1z" />
                                    </svg>
                                </div>
                                <div>
                                    {auction.ownershipType === "week" ? (
                                        <>vk {auction.propertyWeek}</>
                                    ) : (
                                        <>{auction.ownershipType}</>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default AuctionMiniCard;
