import React, { useState } from "react";
import { Form, Input, Button, Space, Alert, Radio, Spin } from "antd";
import api from "../../services/Api";

const RegisterForm = ({ onRegisterSuccess }) => {
    const [form] = Form.useForm();
    const [showCompanyFields, setShowCompanyFields] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const onFinish = async (values) => {
        setLoading(true);
        try {
            await api.createUser(values);
            setError(false);
            form.resetFields();
            alert("Rekisteröinti onnistui! Voit nyt kirjautua sisään.");
            onRegisterSuccess();
        } catch (error) {
            setError("Rekisteröinti epäonnistui. " + (error.response?.data?.message || error.message));
        } finally {
            setLoading(false);
        }
    };

    const onUserTypeChange = (e) => {
        setShowCompanyFields(e.target.value === "company");
    };

    return (
        <>
            {error && <Alert showIcon className="mb-5" message={error} type="error" />}
            {loading ? (
                <Spin />
            ) : (
                <Form
                    form={form}
                    name="register"
                    onFinish={onFinish}
                    layout="horizontal"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 24 }}
                >
                    <Form.Item
                        label="Etunimi"
                        name="firstname"
                        rules={[{ required: true, message: "Etunimi on pakollinen." }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Sukunimi"
                        name="lastname"
                        rules={[{ required: true, message: "Sukunimi on pakollinen." }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Sähköposti"
                        name="email"
                        rules={[
                            { required: true, message: "Sähköpostiosoite on pakollinen." },
                            { type: "email", message: "Tarkista sähköpostiosoitteen muoto." },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Puhelinnumero"
                        name="phonenumber"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Salasana"
                        name="password"
                        rules={[{ required: true, message: "Syötä salasana!" }]}
                        hasFeedback
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        label="Vahvista salasana"
                        name="confirm"
                        dependencies={["password"]}
                        hasFeedback
                        rules={[
                            { required: true, message: "Vahvista salasana" },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue("password") === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error("The two passwords that you entered do not match!")
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        label="Käyttäjätyyppi"
                        name="type"
                        rules={[{ required: true, message: "Valitse käyttäjätilin tyyppi" }]}
                    >
                        <Radio.Group onChange={onUserTypeChange} buttonStyle="solid">
                            <Radio.Button value="individual">Yksityishenkilö</Radio.Button>
                            <Radio.Button value="company">Yritys</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    {showCompanyFields && (
                        <Space direction="vertical" style={{ width: "100%" }}>
                            <Form.Item
                                label="Yrityksen nimi"
                                name="companyName"
                                rules={[{ required: true, message: "Yrityksen nimi on pakollinen." }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Y-tunnus"
                                name="companyId"
                                rules={[{ required: true, message: "Yrityksen Y-tunnus on pakollinen." }]}
                            >
                                <Input />
                            </Form.Item>
                        </Space>
                    )}

                    <Form.Item>
                        <Button type="primary" htmlType="submit" block>
                            Rekisteröidy
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </>
    );
};

export default RegisterForm;
