import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import api from "../../services/Api";
import { Form, Input, Pagination, Select, Table } from "antd";
import { StarFilled, StarOutlined } from "@ant-design/icons";

const Auctions = () => {
    const [auctions, setAuctions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10);
    const [totalAuctions, setTotalAuctions] = useState(0);
    const [type, setType] = useState("");
    const [status, setStatus] = useState("published");
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        setLoading(true);

        const fetchAuctions = async () => {
            try {
                const data = await api.getAdminAuctions(status, type, searchTerm, currentPage, pageSize);
                setAuctions(data.items);
                setTotalAuctions(data.total);
            } catch (error) {
                console.error("Error fetching auctions:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchAuctions();
    }, [status, type, searchTerm, currentPage, pageSize]);

    const token = localStorage.getItem("token");

    if (!token) {
        return <Navigate to="/" />;
    }

    const statusColors = ["#f9a826", "#3bb54a", "#d0021b", "#b0b0b0", "#ee4400"];
    const statusNames = ["draft", "published", "sold", "expired", "cancelled"];
    const statusLabels = {};

    statusNames.forEach((name, index) => {
        statusLabels[name] = statusColors[index];
    });

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleTypeChange = (value) => {
        setType(value);
    };

    const handleStatusChange = (value) => {
        setStatus(value);
    };

    const handleSearchTermChange = (e) => {
        setSearchTerm(e.target.value);
    }

    const columns = [
        {
            title: "Numero",
            dataIndex: "number",
            key: "number",
        },
        {
            title: "Otsikko",
            dataIndex: "title",
            key: "title",
        },
        // {
        //     title: "Huoneisto",
        //     dataIndex: "apartmentDescription",
        //     key: "property",
        // },
        {
            title: "Käyttäjä",
            dataIndex: "userName",
            key: "userName",
        },
        {
            title: "Tyyppi",
            dataIndex: "type",
            key: "type",
            render: (text) => {
                switch (text) {
                    case "auction":
                        return "Huutokauppa";
                    case "sale":
                        return "Myynti";
                    case "rent":
                        return "Vuokraus";
                    default:
                        return "";
                }
            },
        },
        {
            title: "Tila",
            dataIndex: "status",
            key: "status",
            render: (text) => {
                return (
                    <span className="px-2 py-1 rounded text-white" style={{ backgroundColor: statusLabels[text] }}>
                        {text}
                    </span>
                );
            },
        },
        {
            title: "Luotu",
            dataIndex: "createdAt",
            key: "createdAt",
        },
        {
            title: "Etusivu",
            dataIndex: "featured",
            key: "featured",
            render: (text, record) => {
                return (
                    <>
                        {record.isFeatured ? (
                            <StarFilled className="text-green-600" onClick={() => handleToggleFeatured(record.id)} />
                        ) : (
                            <StarOutlined className="text-gray-500" onClick={() => handleToggleFeatured(record.id)} />
                        )}
                    </>
                );
            },
        },
        {
            title: "",
            key: "actions",
            width: 50,
            render: (text, record) => (
                <Link to={`/oma-sivu/ilmoitukset/${record.uuid}`}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-4"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                        />
                    </svg>
                </Link>
            ),
        },
    ];

    const handleToggleFeatured = async (auctionId) => {
        try {
            const response = await api.toggleFeatured(auctionId);

            const updatedAuctions = auctions.map((auction) => {
                if (auction.id === auctionId) {
                    auction.isFeatured = response.isFeatured;
                }
                return auction;
            });
            
            setAuctions(updatedAuctions);
        }
        catch (error) {
            console.error("Error toggling featured:", error);
        }
    }

    return (
        <>
            <Link to="/oma-sivu/ilmoitukset/uusi">
                <div className="text-white bg-green-600 px-5 py-2 rounded hover:bg-green-500 hover:text-white transition-colors duration-200 inline-block float-right">
                    Lisää uusi ilmoitus
                </div>
            </Link>

            <h1 className="text-2xl mb-5">Ilmoitukset</h1>

            <div className="">
                <div className="flex justify-between mb-3">
                    <div className="flex">
                        <Form.Item label="Tila" className="w-44">
                            <Select className="w-44" onChange={handleStatusChange} value={status}>
                                <Select.Option value="">Kaikki</Select.Option>
                                <Select.Option value="draft">Luonnos</Select.Option>
                                <Select.Option value="published">Julkaistu</Select.Option>
                                <Select.Option value="sold">Myyty</Select.Option>
                                <Select.Option value="expired">Vanhentunut</Select.Option>
                                <Select.Option value="cancelled">Peruttu</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item className="w-44 ml-5" label="Tyyppi">
                            <Select className="w-44" onChange={handleTypeChange} value={type}>
                                <Select.Option value="">Kaikki</Select.Option>
                                <Select.Option value="auction">Huutokauppa</Select.Option>
                                <Select.Option value="sale">Myynti</Select.Option>
                                <Select.Option value="rent">Vuokraus</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item className="w-64 ml-5" label="Haku">
                            <Input className="w-44" onKeyUp={handleSearchTermChange} />
                        </Form.Item>

                    </div>
                    <Pagination
                        current={currentPage}
                        pageSize={pageSize}
                        total={totalAuctions}
                        onChange={handlePageChange}
                        showSizeChanger={false}
                        className="mt-4"
                    />
                </div>
                <Table dataSource={auctions} columns={columns} rowKey="id" pagination={false} loading={loading} />
            </div>
            <div className="flex justify-end">
                <Pagination
                    current={currentPage}
                    pageSize={pageSize}
                    total={totalAuctions}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                    className="mt-4"
                />
            </div>
        </>
    );
};

export default Auctions;
