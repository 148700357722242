import React from "react";

const Footer = () => {
    return (
        <footer className="text-center py-4 bg-white">
            <p>&copy; 2024</p>
        </footer>
    );
}

export default Footer;