import React, { useState, useEffect } from "react";
import { Form, Input, Radio, Checkbox } from "antd";

const AuctionFormMandator = ({ onMandatorChange, initialMandator }) => {
    const [isMandatorChecked, setIsMandatorChecked] = useState(initialMandator?.id ? true : false);
    const [mandatorType, setMandatorType] = useState(initialMandator?.type || "Yksityishenkilö");
    const [mandator, setMandator] = useState({
        id: initialMandator?.id || null,
        type: initialMandator?.type || "",
        name: initialMandator?.name || "",
        address: initialMandator?.address || "",
        phonenumber: initialMandator?.phonenumber || "",
        email: initialMandator?.email || "",
        businessId: initialMandator?.businessId || "",
    });

    useEffect(() => {
        // Use a stable reference to prevent excessive updates
        const handleMandatorChange = () => {
            onMandatorChange({ isMandatorChecked, mandatorType, mandator });
        };

        handleMandatorChange();
        // Only run effect when necessary to avoid infinite loops
    }, [isMandatorChecked, mandatorType, mandator]);

    const handleMandatorInputChange = (e) => {
        const { name, value } = e.target;
        setMandator((prevMandator) => ({
            ...prevMandator,
            [name]: value,
        }));
    };

    const handleMandatorCheckboxChange = (e) => {
        setIsMandatorChecked(e.target.checked);
    };

    const handleMandatorTypeChange = (e) => {
        setMandatorType(e.target.value);
        setMandator((prevMandator) => ({
            ...prevMandator,
            type: e.target.value,
        }));
    };

    return (
        <div>
            <div className="text-xl font-bold mb-2">Toimeksiannon tiedot</div>

            <Form.Item>
                <Checkbox checked={isMandatorChecked} onChange={handleMandatorCheckboxChange}>
                    Kohde myydään toimeksiantona, toimin vain välittäjänä
                </Checkbox>
            </Form.Item>

            {isMandatorChecked && (
                <>
                    <div className="w-1/2">
                        <Form.Item label="Toimeksiantaja on">
                            <Radio.Group
                                name="type"
                                value={mandatorType}
                                onChange={handleMandatorTypeChange}
                                optionType="button"
                                size={"medium"}
                                buttonStyle="solid"
                            >
                                <Radio.Button value="company">Yritys</Radio.Button>
                                <Radio.Button value="person">Yksityishenkilö</Radio.Button>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item label="Nimi">
                            <Input name="name" value={mandator.name} onChange={handleMandatorInputChange} />
                        </Form.Item>

                        <Form.Item label="Osoite">
                            <Input name="address" value={mandator.address} onChange={handleMandatorInputChange} />
                        </Form.Item>

                        <Form.Item label="Puhelinnumero">
                            <Input
                                name="phonenumber"
                                value={mandator.phonenumber}
                                onChange={handleMandatorInputChange}
                            />
                        </Form.Item>

                        <Form.Item label="Sähköposti">
                            <Input name="email" value={mandator.email} onChange={handleMandatorInputChange} />
                        </Form.Item>

                        {mandatorType === "company" && (
                            <Form.Item label="Y-tunnus">
                                <Input
                                    name="businessId"
                                    value={mandator.businessId}
                                    onChange={handleMandatorInputChange}
                                />
                            </Form.Item>
                        )}
                    </div>
                    <Checkbox className="terms mb-2 font-medium" name="terms[]" value="1" required={true}>
                        Olen varmistanut toimeksiantajan omistusoikeuden kohteeseen.
                    </Checkbox>
                    <Checkbox className="terms font-medium" name="terms[]" value="1" required={true}>
                        En missään tapauksessa tilitä kauppasummaa toimeksiantajalle ennen kohteen todennettua
                        luovutusta ostajalle. Näin estän olemattoman tavaran myynnin josta ilmoittajana olen vastuussa.
                    </Checkbox>
                </>
            )}
        </div>
    );
};

export default AuctionFormMandator;
