import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import api from "../../services/Api";
import AuctionCard from "./AuctionCard";

const AuctionList = ({ filters, limit }) => {
    const [auctions, setAuctions] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchAuctions(filters, limit);
    }, [filters, limit]);

    const fetchAuctions = async (filters, limit) => {
        setLoading(true);

        try {
            const response = await api.getAuctions(filters, limit);
            setAuctions(response);
        } catch (error) {
            console.error("Error fetching auctions:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="mb-10 relative">
            {!loading & (auctions.length === 0) ? (
                <div className="text-2xl text-gray-800 mt-5">Valitettavasti haku ei löytänyt yhtään kohdetta.</div>
            ) : (
                <div className="relative">
                    {loading && (
                        <div className="flex justify-center items-center absolute w-full bg-white bg-opacity-25 h-full z-20">
                            <Spin size="large" />
                        </div>
                    )}
                    {!limit && (
                        <div className="text-2xl text-gray-800 mb-2 mt-5">
                            Löytyi {auctions.length} kohde{auctions.length > 1 && "tta"}
                        </div>
                    )}
                    <div className="md:grid md:grid-cols-4 md:gap-5">
                        {auctions?.map((auction) => (
                            <AuctionCard key={auction.number} auction={auction} />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default AuctionList;
