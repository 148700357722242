import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const Sidebar = () => {
    const { user, hasRole } = useContext(AuthContext);
    const linkClass = 'text-white bg-sky-600 px-5 py-2 rounded hover:bg-sky-500 hover:text-white block mb-2 transition-colors duration-200';
    const subHeaderClass = 'text-black bg-gray-300 px-5 py-1 rounded block mb-2';

    return (
        <>
            <Link className={linkClass} to="/oma-sivu">Oma tili</Link>
            <Link className={linkClass} to="/oma-sivu/viestit">Viestit</Link>
            <Link className={linkClass} to="/oma-sivu/ilmoitukset">Ilmoitukset</Link>
            <Link className={linkClass} to="/oma-sivu/kuvapankki">Kuvapankki</Link>
            <Link className={linkClass}>Arkisto</Link>
            {hasRole(user.roles, 'ROLE_SUPERADMIN') && (
                <>
                    <div className={subHeaderClass}>SUPERADMIN</div>
                    <Link className={linkClass} to="/hallinta/ilmoitukset">Ilmoitukset</Link>
                    <Link className={linkClass} to="/hallinta/kohteet">Kohteet</Link>
                    <Link className={linkClass} to="/hallinta/asetukset">Asetukset</Link>
                </>
            )}
            {hasRole(user.roles, 'ROLE_GOD') && (
                <>
                    <div className={subHeaderClass}>GOD</div>
                    <Link className={linkClass} to="/hallinta/kayttajat">Käyttäjät</Link>
                    <Link className={linkClass} to="/hallinta/toiminnot">Toiminnot</Link>
                </>
            )}
        </>
    );
}

export default Sidebar;