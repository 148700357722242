import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LoginOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import { HeartOutlined } from "@ant-design/icons";

import api from "../../services/Api";

import logoWhite from "../../assets/images/lomahuuto-fi-logo-white-1024.png";
import logoDark from "../../assets/images/lomahuuto-fi-logo-1024.png";
import LoginModal from "../modals/LoginModal";
import FavoritesDrawer from "./FavoritesDrawer";

const Header = ({ pageType }) => {
    const { isAuthenticated, logout } = useContext(AuthContext);
    const { showLoginModal, isLoginModalOpen, hideLoginModal } = useContext(ModalContext);
    const [sticky, setSticky] = useState(false);
    const [favorites, setFavorites] = useState([]);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const showDrawer = () => {
        setOpen(true);
        fetchFavorites();
    };

    const onClose = () => {
        setOpen(false);
    };

    const fetchFavorites = async () => {
        setLoading(true);

        try {
            const response = await api.getFavoriteAuctions();
            setFavorites(response);
        } catch (error) {
            console.error("Error fetching auctions:", error);
        } finally {
            setLoading(false);
        }
    };

    const stickyClass = ["sticky", "top-0", "bg-primary", "z-50", "text-white", "p-2", "md:py-3"];
    const headerClass = [
        "transition-all",
        "duration-300",
        "ease-in-out",
        "p-5",
        "md:py-5",
        pageType === 0 ? "" : "bg-primary",
    ];

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 150) {
                setSticky(true);
            } 
            
            if (window.scrollY < 100) {
                setSticky(false);
            }
        });
    });

    return (
        <>
            <header
                className={sticky ? `${headerClass.join(" ")} ${stickyClass.join(" ")}` : `${headerClass.join(" ")}`}
            >
                <div className={"container m-auto max-w-screen-2xl flex md:px-0"}>
                    <Link to="/">
                        <img
                            src={pageType === 1 || sticky ? logoWhite : logoDark}
                            alt="Lomahuuto.fi"
                            width={sticky ? 200 : 300}
                        />
                    </Link>
                    <div className="flex-grow mx-10">
                        {/* <input type="text" className="p-2 border border-gray-300 rounded" placeholder="Search..." /> */}
                    </div>
                    <div className="flex items-center text-white">
                        <div
                            className={
                                pageType === 1 || sticky
                                    ? "flex items-center text-primary cursor-pointer"
                                    : "flex items-center text-primary cursor-pointer"
                            }
                        >
                            {isAuthenticated ? (
                                <>
                                    <Link
                                        className={
                                            pageType === 1 || sticky
                                                ? "mr-4 text-gray-100 hover:text-white flex"
                                                : "mr-4 text-primary hover:text-black flex"
                                        }
                                        to="/oma-sivu"
                                    >
                                        <UserOutlined className="mr-2" />
                                        Oma tili
                                    </Link>
                                    <div
                                        className={
                                            pageType === 1 || sticky
                                                ? "mr-4 text-gray-100 hover:text-white flex"
                                                : "mr-4 text-primary hover:text-black flex"
                                        }
                                        onClick={showDrawer}
                                    >
                                        <HeartOutlined className="mr-2" />
                                        Suosikit
                                    </div>
                                    <Link
                                        className={
                                            pageType === 1 || sticky
                                                ? "mr-4 text-gray-100 hover:text-white flex"
                                                : "mr-4 text-primary hover:text-black flex"
                                        }
                                        onClick={logout}
                                    >
                                        <LogoutOutlined className="mr-2" />
                                        Kirjaudu ulos
                                    </Link>
                                    <FavoritesDrawer open={open} onClose={onClose} favorites={favorites} loading={loading} />
                                </>
                            ) : (
                                <div
                                    onClick={showLoginModal}
                                    className={
                                        pageType === 1 || sticky
                                            ? "text-gray-100 hover:text-white flex"
                                            : "text-primary hover:text-black flex"
                                    }
                                >
                                    <LoginOutlined className="mr-2 text-lg md:text-base" />
                                    <span className="hidden md:block">Kirjaudu</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <LoginModal isOpen={isLoginModalOpen} onClose={hideLoginModal} />
            </header>
        </>
    );
};

export default Header;
