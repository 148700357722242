import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { Spin, InputNumber, Button, Form, message, Switch, Modal, message as antdMessage, Input, Popover } from "antd";
import { SoundOutlined, SoundFilled, HeartOutlined, HeartFilled } from "@ant-design/icons";

import AuctionGallery from "../components/common/AuctionGallery";
import AuctionDetails from "../components/common/AuctionDetails";

import { AuthContext } from "../context/AuthContext";
import { ModalContext } from "../context/ModalContext";

import api from "../services/Api";
import AuctionCountdownTimer from "../components/common/AuctionCountdownTimer";

const AuctionPage = () => {
    const { number, slug } = useParams();
    const { isAuthenticated, user } = useContext(AuthContext);
    const { showLoginModal } = useContext(ModalContext);
    const [auction, setAuction] = useState(null);
    const [loading, setLoading] = useState(true);
    const [bidding, setBidding] = useState(false);
    const [bidAmount, setBidAmount] = useState(null);
    const [bidFormError, setBidFormError] = useState("");
    const [soundEnabled, setSoundEnabled] = useState(true);
    const [userBidCount, setUserBidCount] = useState(0);
    const [messageContent, setMessageContent] = useState("");
    const [sendingMessage, setSendingMessage] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isTermsModalVisible, setIsTermsModalVisible] = useState(false);
    const [terms, setTerms] = useState({
        label: "",
        content: "",
    });
    const audioRef = useRef(new Audio("../../assets/sounds/bid-chime.wav"));
    const previousBidderId = useRef(null);
    const [favorite, setFavorite] = useState(auction?.isFavorite);

    useEffect(() => {
        const fetchAuction = async () => {
            try {
                const response = await api.getAuction(number);
                setAuction(response);
                setUserBidCount(response.bids.filter((bid) => bid.user === user.uuid).length);

                if (response.bids.length === 0) {
                    setBidAmount(response.startPrice + response.minBid);
                } else {
                    setBidAmount(response.bids[0].amount + response.minBid);
                }

                setFavorite(response.isFavorite);

                if (!slug) {
                    window.history.replaceState({}, "", `/kohde/${number}/${response.slug}`);
                }

                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };

        if (number) {
            fetchAuction();
        }
    }, [number]);

    useEffect(() => {
        const fetchBids = async () => {
            try {
                const response = await api.getAuction(number);
                setAuction((prevAuction) => ({
                    ...prevAuction,
                    bids: response.bids,
                }));
            } catch (error) {
                console.error("Error fetching bids:", error);
            }
        };

        const interval = setInterval(fetchBids, 60000);

        return () => clearInterval(interval);
    }, [number]);

    useEffect(() => {
        if (auction && auction.bids.length > 0) {
            const lastBid = auction.bids[0];

            if (lastBid.user && previousBidderId.current !== lastBid.user && lastBid.user !== user) {
                previousBidderId.current = lastBid.user;

                if (soundEnabled) {
                    audioRef.current.load(); // Ensure the audio is loaded before playing
                    audioRef.current.play().catch((error) => {
                        console.error("Error playing audio:", error);
                    });
                }
            }
        }
    }, [auction, soundEnabled, user]);

    if (loading) {
        return <Spin />;
    }

    const handleBidSubmit = () => {
        if (!bidAmount || bidAmount <= 0) {
            message.error("Please enter a valid bid amount");
            return;
        }

        // Show the confirmation modal
        setIsConfirmModalVisible(true);
    };

    const handleConfirmBid = async () => {
        setIsConfirmModalVisible(false);
        setBidding(true);
        setBidFormError("");

        try {
            const response = await api.submitBid(number, { amount: bidAmount });
            setAuction((prevAuction) => ({
                ...prevAuction,
                bids: [response, ...prevAuction.bids],
            }));

            setBidAmount(response.amount + auction.minBid);
        } catch (error) {
            setBidFormError(error.response.data.message);
        } finally {
            setBidding(false);
        }
    };

    const dateOptions = {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
        timeZone: "Europe/Helsinki",
    };

    const confirmOffer = async () => {
        const { confirm } = Modal;
        let offerAmount = auction.startPrice;

        confirm({
            title: "Vahvista tarjous",
            content: (
                <>
                    <p>Tee tarjous kohteesta {auction.property.title}:</p>
                    <InputNumber
                        min={auction.startPrice}
                        defaultValue={auction.startPrice}
                        onChange={(value) => {
                            offerAmount = value;
                        }}
                        style={{ width: "100%" }}
                        addonAfter="€"
                    />
                </>
            ),
            okText: "Tarjoa",
            cancelText: "Peruuta",
            onOk() {
                setBidding(true);
                setBidFormError("");

                try {
                    const response = api.submitOffer(number, { amount: offerAmount });

                    setAuction((prevAuction) => ({
                        ...prevAuction,
                        bids: [response, ...prevAuction.bids],
                    }));

                    setUserBidCount(auction.bids.filter((bid) => bid.user === user.uuid).length);
                } catch (error) {
                    setBidFormError(error.response.data.message);
                } finally {
                    setBidding(false);
                }
            },
        });
    };

    const handleSendChatMessage = async () => {
        const messageContainer = document.querySelector(".send-message");

        if (!messageContent.trim()) {
            antdMessage.error("Please enter a message");
            return;
        }

        setSendingMessage(true);

        const payload = {
            content: messageContent,
            auction_number: auction.number,
            chat_uuid: null,
        };

        try {
            await api.sendChatMessage(payload);
            setMessageContent("");
            messageContainer.innerHTML =
                '<div className="bg-green-600 rounded h-36 flex flex-col items-center justify-center text-white">' +
                '<div className="text-center font-bold">Viesti lähetetty!</div>' +
                '<div className="text-center">Voit jatkaa keskustelua<br />' +
                '<a className="underline text-green-100 hover:text-white hover:underline" href="/oma-sivu/viestit">omassa profiilissasi</a></div>' +
                "</div>";
        } catch (error) {
            console.error("Error sending message:", error);
            antdMessage.error("Error sending message");
        } finally {
            setSendingMessage(false);
        }
    };

    const toggleFavorite = async (e) => {
        e.preventDefault();

        if (isAuthenticated) {
            const response = await api.toggleFavorite(auction.number);

            setFavorite(response.isFavorite);
            antdMessage.success(response.isFavorite ? "Kohde lisätty suosikkeihin" : "Kohde poistettu suosikeista");
        } else {
            showLoginModal();
        }
    };

    const copyUrlToClipboard = () => {
        const url = window.location.href;
        navigator.clipboard.writeText(url).then(() => {
            antdMessage.success("Linkki kopioitu");
        });
    };

    const handleTermsModalOpen = (settingsName) => async () => {
        try {
            const response = await api.getSetting(settingsName);
            setTerms({
                label: response.label,
                content: response.value,
            });
            setIsTermsModalVisible(true);
        } catch (error) {
            console.error("Error fetching terms:", error);
        }
    };

    return (
        <div className="container max-w-screen-2xl m-auto text-lg p-5 md:p-0">
            {auction && (
                <>
                    <div className="mb-5">
                        <h1 className="uppercase text-3xl m-0 flex justify-between">
                            <span className="flex-1">{auction.property.title}</span>
                            {isAuthenticated && (
                                <div className="text-red-500 text-3xl cursor-pointer mr-5" onClick={toggleFavorite}>
                                    {favorite ? <HeartFilled /> : <HeartOutlined />}
                                </div>
                            )}
                            <span className="text-gray-400 cursor-pointer">
                                <Popover content="Kopioi linkki sivulle" onClick={copyUrlToClipboard}>
                                    #{auction.number}
                                </Popover>
                            </span>
                        </h1>
                        <h3 className="text-xl text-gray-600">
                            {auction.apartmentDescription} &bull;{" "}
                            {auction.property.ownershipType === "week"
                                ? "viikko " + auction.propertyWeek
                                : auction.property.ownershipType + " osaomistus"}
                        </h3>
                    </div>
                    <div className="block md:flex w-auto">
                        <div className="w-auto md:w-3/4 mr-0 md:mr-5">
                            <AuctionGallery images={auction.images} />
                            <AuctionDetails auction={auction} />
                            <div className="mb-5">
                                <h3>Kaupan ehdot</h3>
                                {auction.type === "rent" ? (
                                    <div
                                        className="text-primary cursor-pointer"
                                        onClick={handleTermsModalOpen("rental_terms")}
                                    >
                                        Vuokrausehdot
                                    </div>
                                ) : (
                                    <div
                                        className="text-primary cursor-pointer"
                                        onClick={handleTermsModalOpen("sale_terms")}
                                    >
                                        Myyntiehdot
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="w-auto md:w-1/4 bg-primary rounded-md p-5">
                            {auction.type === "sale" && (
                                <>
                                    <div className="bg-emerald-300 p-4 mb-5 text-lg rounded">
                                        <div className="uppercase">Myyntihinta</div>
                                        <div className="text-5xl mb-5">{auction.startPrice} €</div>
                                        <div className="uppercase">Viikko</div>
                                        <div className="text-3xl">{auction.propertyWeek}</div>
                                    </div>
                                    <div className="mb-5">
                                        {userBidCount > 0 ? (
                                            <div className="text-black bg-yellow-400 p-2 rounded mb-2 text-center text-base">
                                                Olet jo tehnyt tarjouksen tästä kohteesta
                                            </div>
                                        ) : (
                                            <>
                                                <Form layout="vertical">
                                                    <Form.Item>
                                                        <Button
                                                            type="primary"
                                                            size="large"
                                                            htmlType="submit"
                                                            loading={bidding}
                                                            className="bg-emerald-500 text-white font-bold w-full"
                                                            disabled={bidding ? true : false}
                                                            onClick={confirmOffer}
                                                        >
                                                            Tee tarjous
                                                        </Button>
                                                    </Form.Item>
                                                </Form>
                                                {bidFormError && (
                                                    <div className="bg-red-700 text-white px-4 py-2 rounded text-sm">
                                                        {bidFormError}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </>
                            )}
                            {auction.type === "auction" && (
                                <>
                                    <h2 className="text-white uppercase flex justify-between">
                                        Huutokauppa
                                        <Switch
                                            checkedChildren={<SoundFilled />}
                                            unCheckedChildren={<SoundOutlined />}
                                            checked={soundEnabled}
                                            onChange={() => setSoundEnabled(!soundEnabled)}
                                        />
                                    </h2>
                                    <div className="bg-emerald-300 p-4 text-lg rounded mb-5">
                                        {auction.bids.length > 0 && (
                                            <>
                                                <div className="uppercase">Korkein tarjous</div>
                                                <div className="text-5xl font-bold mb-5">
                                                    {auction.bids[0].amount} €
                                                </div>
                                            </>
                                        )}
                                        {auction.status === "expired" ? (
                                            <div>Myynti on päättynyt</div>
                                        ) : (
                                            <>
                                                <div className="uppercase">Aikaa jäljellä</div>
                                                <div className="text-2xl font-bold">
                                                    <AuctionCountdownTimer auction={auction} />
                                                </div>
                                                <div className="text-sm mt-2 text-emerald-700">
                                                    Huutokauppa päättyy{" "}
                                                    {new Date(auction.ends.date).toLocaleString("fi-FI")}
                                                </div>
                                            </>
                                        )}
                                    </div>

                                    <div className="bg-blue-500 rounded p-2 text-white text-sm text-center mb-5">
                                        {auction.confirmedSale ? (
                                            <>
                                                <div className="font-bold text-xl mb-2">Varmat kaupat!</div>
                                                <p>
                                                    Myyjä on sitoutunut myymään kohteen korkeimman tarjouksen tehneelle.
                                                </p>
                                            </>
                                        ) : (
                                            "Myyjä pidättää oikeuden hyväksyä tai hylätä korkeimman tarjouksen."
                                        )}
                                    </div>

                                    {auction.status === "published" && (
                                        <>
                                            {auction.bids.length === 0 && (
                                                <div className="text-white mb-5">
                                                    <div className="font-bold text-sm">
                                                        Ei vielä tarjouksia, lähtöhinta:
                                                    </div>
                                                    <div>{auction.startPrice} €</div>
                                                </div>
                                            )}

                                            <h3 className="text-white mb-2">Tee tarjous kohteesta</h3>
                                            {isAuthenticated ? (
                                                <div className=" mb-5">
                                                    <Form layout="vertical" onFinish={handleBidSubmit} className="flex">
                                                        <Form.Item className="mr-3">
                                                            <InputNumber
                                                                min={0}
                                                                size="large"
                                                                value={bidAmount}
                                                                onChange={(value) => setBidAmount(value)}
                                                                className="w-full"
                                                                addonAfter="€"
                                                            />
                                                        </Form.Item>
                                                        <Form.Item>
                                                            <Button
                                                                type="primary"
                                                                size="large"
                                                                htmlType="submit"
                                                                loading={bidding}
                                                                className="bg-emerald-500 text-white font-bold"
                                                                disabled={bidding ? true : false}
                                                            >
                                                                Tarjoa
                                                            </Button>
                                                        </Form.Item>
                                                    </Form>
                                                    {bidFormError && (
                                                        <div className="bg-red-700 text-white px-4 py-2 rounded text-sm">
                                                            {bidFormError}
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                <div className="mb-5 text-white text-sm">
                                                    <div className="mb-2">Kirjaudu sisään tehdäksesi tarjouksen</div>
                                                    <div className="grid grid-cols-2 gap-2">
                                                        <Button
                                                            className="bg-emerald-300"
                                                            size="large"
                                                            onClick={showLoginModal}
                                                        >
                                                            Kirjaudu
                                                        </Button>
                                                        <Button size="large" onClick={showLoginModal}>
                                                            Rekisteröidy
                                                        </Button>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}

                                    <h3 className="text-white p-2 bg-primary-700 rounded">
                                        <div className=" flex justify-between">
                                        <div>
                                            <div className="text-sm">Tarjouksia</div>
                                            <div className="text-2xl">{auction.bids.length} kpl</div>
                                        </div>
                                        <div className="text-right">
                                            <div className="text-sm">Tarjoajia</div>
                                            <div className="text-2xl">{auction.bidders}</div>
                                        </div>
                                        </div>
                                        {auction.userHasHighestBid && (
                                            <div className="bg-emerald-400 text-black font-normal px-4 py-1 text-lg rounded mt-2 text-center">
                                                Sinulla on korkein tarjous!
                                            </div>
                                        )}
                                    </h3>
                                    <ul className="text-gray-50 p-2 bg-primary-400 border border-primary-700 text-sm max-h-64 overflow-y-auto scrollbar-thumb-primary-200 scrollbar-track-primary-700 scrollbar-thin">
                                        {auction?.bids.map((bid, index) => (
                                            <li
                                                key={index}
                                                className="flex mb-1 pb-1 border-b border-b-primary-600 last:border-none"
                                            >
                                                <div className="font-bold">{bid.amount}€</div>
                                                <div className="flex-1 text-primary-50 ml-2">
                                                    {new Date(bid.createdAt?.date).toLocaleString("fi-FI", dateOptions)}
                                                </div>
                                                <div>{bid.name}</div>
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            )}
                            <h3 className="text-white mb-2 mt-5">Lähetä viesti myyjälle</h3>
                            {isAuthenticated ? (
                                <>
                                    <div className="send-message">
                                        <Form layout="vertical" onFinish={handleSendChatMessage}>
                                            <Form.Item>
                                                <Input.TextArea
                                                    rows={6}
                                                    value={messageContent}
                                                    onChange={(e) => setMessageContent(e.target.value)}
                                                    placeholder="Kirjoita viesti..."
                                                />
                                            </Form.Item>
                                            <Form.Item>
                                                <Button
                                                    className="bg-emerald-500 text-white font-bold w-full"
                                                    type="primary"
                                                    htmlType="submit"
                                                    size="large"
                                                    loading={sendingMessage}
                                                    disabled={sendingMessage}
                                                >
                                                    Lähetä viesti
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </>
                            ) : (
                                <div>
                                    <Button type="primary" onClick={showLoginModal}>
                                        Kirjaudu sisään lähettääksesi viestin
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
            <Modal
                title="Vahvista tarjous"
                open={isConfirmModalVisible}
                onOk={handleConfirmBid}
                onCancel={() => setIsConfirmModalVisible(false)}
                okText="Vahvista tarjous"
                cancelText="Peruuta"
            >
                <p>Olet tekemässä tarjousta summalla {bidAmount} €. Muistathan, että tarjous on sitova!</p>
            </Modal>
            <Modal
                title={terms.label}
                open={isTermsModalVisible}
                onOk={() => setIsTermsModalVisible(false)}
                onCancel={() => setIsTermsModalVisible(false)}
                okText="Sulje"
                width={800}
                footer={
                    <Button type="primary" onClick={() => setIsTermsModalVisible(false)}>
                        Sulje
                    </Button>
                }
            >
                <div dangerouslySetInnerHTML={{ __html: terms.content }} />
            </Modal>
        </div>
    );
};

export default AuctionPage;
