import React from "react";
import { Link } from "react-router-dom";

import Header from "./Header";
import AuctionSearchBar from "./AuctionSearchBar";

import backgroundImage from "../../assets/images/lomahuuto-hero-2.jpg";

const Hero = () => {
    const heroStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
    };

    return (
        <div style={heroStyle}>
            <Header pageType={0} />
            <div className="flex flex-col max-w-screen-2xl m-auto items-center">
                <div className="z-10 bg-transparent mt-20 md:mt-20 p-5 md:p-0 flex flex-col md:block text-center md:text-center">
                    <h1 className="text-3xl mb-10 text-primary font-light">
                        Myy, vuokraa tai huutokauppaa lomaosakkeesi tänään!
                    </h1>
                    {/* <div className="mb-10 text-xl">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div> */}
                    <Link to="/oma-sivu/ilmoitukset/uusi">
                        <div className="py-2 px-6 mb-5 md:mb-0 inline-block bg-primary text-white text-lg uppercase rounded-full hover:text-white hover:bg-primary-50 transition-all">
                            Luo ilmoitus
                        </div>
                    </Link>
                    <Link to="/kirjaudu">
                        <div className="py-2 px-6 inline-block md:ml-4 bg-primary-200 text-white text-lg uppercase rounded-full hover:text-white hover:bg-primary-50 transition-all">
                            Rekisteröidy
                        </div>
                    </Link>
                </div>
                <div className="m-10 mb-32 w-full md:w-2/3">
                    <AuctionSearchBar />
                </div>
            </div>
        </div>
    );
};

export default Hero;
