import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ConfigProvider, Layout } from "antd";

import { AuthProvider } from "./context/AuthContext";
import { ModalProvider } from "./context/ModalContext";

import ProtectedRoute from "./components/security/ProtectedRoute";

import TagManager from 'react-gtm-module';

import FrontPageLayout from "./components/layouts/FrontpageLayout";
import MainLayout from "./components/layouts/MainLayout";
import AdminLayout from "./components/layouts/AdminLayout";

import Home from "./pages/Home";
import Login from "./pages/Login";
import AuctionPage from "./pages/AuctionPage";
import AuctionListPage from "./pages/AuctionListPage";

import Profile from "./pages/admin/Profile";
import Auctions from "./pages/admin/Auctions";
import UserAuctions from "./pages/admin/UserAuctions";
import Auction from "./pages/admin/Auction";
import Chats from "./pages/admin/Chats";
import Gallery from "./pages/admin/Gallery";
import Properties from "./pages/admin/Properties";
import PropertyEdit from "./pages/admin/PropertyEdit";
import Settings from "./pages/admin/Settings";
import AdminActions from "./pages/admin/AdminActions";
import Users from "./pages/admin/Users";

const App = () => {
    const routes = [
        {
            'path': '/',
            'element': <FrontPageLayout><Home /></FrontPageLayout>,
        },
        {
            'path': '/kirjaudu',
            'element': <MainLayout><Login /></MainLayout>,
        },
        {
            'path': '/kohteet',
            'element': <MainLayout><AuctionListPage /></MainLayout>,
        },
        {
            'path': '/kohteet/:type',
            'element': <MainLayout><AuctionListPage /></MainLayout>,
        },
        {
            'path': '/kohde/:number',
            'element': <MainLayout><AuctionPage /></MainLayout>,
        },
        {
            'path': '/kohde/:number/:slug',
            'element': <MainLayout><AuctionPage /></MainLayout>,
        },
        {
            'element': <ProtectedRoute requiredRole="ROLE_USER" />,
            'children': [
                {
                    'path': '/oma-sivu',
                    'element': <AdminLayout><Profile /></AdminLayout>,
                },
                {
                    'path': '/oma-sivu/viestit',
                    'element': <AdminLayout><Chats /></AdminLayout>,
                },
                {
                    'path': '/oma-sivu/viestit/:chatUuid',
                    'element': <AdminLayout><Chats /></AdminLayout>,
                },
                {
                    'path': '/oma-sivu/ilmoitukset',
                    'element': <AdminLayout><UserAuctions /></AdminLayout>,
                },
                {
                    'path': '/oma-sivu/ilmoitukset/:uuid',
                    'element': <AdminLayout><Auction /></AdminLayout>,
                },
                {
                    'path': '/oma-sivu/kuvapankki',
                    'element': <AdminLayout><Gallery /></AdminLayout>,
                },
                {
                    'path': '/oma-sivu/kuvapankki/:folderId',
                    'element': <AdminLayout><Gallery /></AdminLayout>,
                },
            ],
        },
        {
            'element': <ProtectedRoute requiredRole="ROLE_SUPERADMIN" />,
            'children': [
                {
                    'path': '/hallinta/kohteet',
                    'element': <AdminLayout><Properties /></AdminLayout>,
                },
                {
                    'path': '/hallinta/kohde/uusi',
                    'element': <AdminLayout><PropertyEdit /></AdminLayout>,
                },
                {
                    'path': '/hallinta/kohde/muokkaa/:id',
                    'element': <AdminLayout><PropertyEdit /></AdminLayout>,
                },
                {
                    'path': '/hallinta/asetukset',
                    'element': <AdminLayout><Settings /></AdminLayout>,
                },
                {
                    'path': '/hallinta/ilmoitukset',
                    'element': <AdminLayout><Auctions /></AdminLayout>,
                },
            ],
        },
        {   'element': <ProtectedRoute requiredRole="ROLE_GOD" />,
            'children': [
                {
                    'path': '/hallinta/kayttajat',
                    'element': <AdminLayout><Users /></AdminLayout>,
                },
                {
                    'path': '/hallinta/toiminnot',
                    'element': <AdminLayout><AdminActions /></AdminLayout>,
                },
            ],
        },
    ];

    useEffect(() => {
        const tagManagerArgs = {
            gtmId: 'AW-11328598452'
        };
        TagManager.initialize(tagManagerArgs);
    }, []);

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: "#01696e",
                    colorInfo: "#01696e",
                    colorWarning: "#dabe1f",
                    colorSuccess: "#62a93e",
                    colorLink: "#2f54eb",
                    borderRadius: 4,
                    colorError: "#e13235",
                    wireframe: false,
                },
                components: {
                    Radio: {
                        buttonSolidCheckedBg: "#00b96b",
                        buttonSolidCheckedHoverBg: "#00b96b",
                    },
                },
            }}
        >
            <ModalProvider>
                <Router>
                    <AuthProvider>
                        <Layout className="bg-white font-Outfit">
                            <Routes>
                                {routes.map((route, index) => {
                                    if (route.children) {
                                        return (
                                            <Route
                                                key={index}
                                                element={route.element}
                                            >
                                                {route.children.map((child, index) => (
                                                    <Route
                                                        key={index}
                                                        path={child.path}
                                                        element={child.element}
                                                    />
                                                ))}
                                            </Route>
                                        );
                                    } else {
                                        return (
                                            <Route
                                                key={index}
                                                path={route.path}
                                                element={route.element}
                                            />
                                        );
                                    }
                                })}
                            </Routes>
                        </Layout>
                    </AuthProvider>
                </Router>
            </ModalProvider>
        </ConfigProvider>
    );
};

export default App;
