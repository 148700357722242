import React from "react";
import AuctionList from "../components/common/AuctionList";
import { Link } from "react-router-dom";
import AuctionFeaturedList from "../components/common/AuctionFeaturedList";

const Home = () => {
    return (
        <div className="bg-gray-50 w-full p-5 2xl:p-0">
            <section className="container max-w-screen-2xl m-auto pt-10">
                <h3 className="text-xl md:text-2xl mb-5 font-medium uppercase text-primary">
                    Lomahuuto.fi kuukauden valinnat
                </h3>
                <AuctionFeaturedList />
            </section>
            <section className="container max-w-screen-2xl m-auto pt-10">
                <div className="flex justify-between items-center">
                    <Link to="/kohteet?t=auction" className="w-full">
                        <div className="flex justify-between items-center mb-5">
                            <h2 className="text-xl md:text-3xl mb-0 font-medium uppercase text-primary">
                                Huutokauppakohteet
                            </h2>
                            <div className="text-primary text-lg flex items-center">
                                <span className="hidden md:block">Katso kaikki</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="size-5 ml-2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5"
                                    />
                                </svg>
                            </div>
                        </div>
                    </Link>
                </div>
                <AuctionList filters={{ types:['auction'] }} limit={4} />
                <div className="flex justify-between items-center">
                    <Link to="/kohteet?t=sale" className="w-full">
                        <div className="flex justify-between items-center mb-5">
                            <h2 className="text-xl md:text-3xl mb-0 font-medium uppercase text-primary">
                                Myytävät kohteet
                            </h2>
                            <div className="text-primary text-lg flex items-center">
                                <span className="hidden md:block">Katso kaikki</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="size-5 ml-2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5"
                                    />
                                </svg>
                            </div>
                        </div>
                    </Link>
                </div>
                <AuctionList filters={{ types:['sale'] }} limit={4} />
                <div className="flex justify-between items-center">
                    <Link to="/kohteet?t=rent" className="w-full">
                        <div className="flex justify-between items-center mb-5">
                            <h2 className="text-xl md:text-3xl mb-0 font-medium uppercase text-primary">
                                Vuokrattavat kohteet
                            </h2>
                            <div className="text-primary text-lg flex items-center">
                                <span className="hidden md:block">Katso kaikki</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="size-5 ml-2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5"
                                    />
                                </svg>
                            </div>
                        </div>
                    </Link>
                </div>
                <AuctionList filters={{ types:['rent'] }} limit={4} />
            </section>
        </div>
    );
};

export default Home;
