import React, { useState, forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";

const CustomDatePicker = ({ selectedDate, onDateChange }) => {
    const [hoveredDate, setHoveredDate] = useState(null);

    const handleDayMouseEnter = (date) => setHoveredDate(date);
    const handleDayMouseLeave = () => setHoveredDate(null);

    const renderDayContents = (day, date) => {
        const isHovered = hoveredDate && date === hoveredDate;

        return (
            <div
                className={`rounded ${isHovered ? "bg-blue-500 text-white" : ""}`}
                onMouseEnter={() => handleDayMouseEnter(date)}
                onMouseLeave={handleDayMouseLeave}
            >
                {day}
            </div>
        );
    };

    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <input
            type="text"
            value={value}
            onClick={onClick}
            ref={ref}
            className="border border-gray-300 rounded text-base cursor-pointer"
            placeholder="Valitse päivämäärä"
            style={{
                padding: "3px 20px 3px 10px",
            }}
            readOnly
        />
    ));

    const renderCustomHeader = ({ date, decreaseMonth, increaseMonth }) => {
        const formattedMonth = dayjs(date).locale("fi").format("MMMM").slice(0, -2).toUpperCase();
        const formattedYear = dayjs(date).format("YYYY");

        return (
            <div className="flex items-center justify-between px-2 bg-gray-200 rounded-t-lg">
                <div
                    onClick={decreaseMonth}
                    className="p-2 rounded-lg hover:bg-gray-300 focus:outline-none cursor-pointer"
                >
                    {"<"}
                </div>
                <span className="text-sm font-bold">
                    {formattedMonth} {formattedYear}
                </span>
                <div
                    onClick={increaseMonth}
                    className="p-2 rounded-lg hover:bg-gray-300 focus:outline-none cursor-pointer"
                >
                    {">"}
                </div>
            </div>
        );
    };    

    return (
        <div className="relative">
            <DatePicker
                selected={selectedDate}
                onChange={(date) => onDateChange(dayjs(date).tz('Europe/Helsinki').toISOString())} // Use dayjs for ISO format
                showIcon
                icon={
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 5.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                        />
                    </svg>
                }
                calendarIconClassName="text-gray-300 absolute right-0 top-0 text-lg"
                dayClassName={() => "custom-day"}
                customInput={<CustomInput />}
                renderDayContents={renderDayContents}
                renderCustomHeader={renderCustomHeader}
                popperPlacement="bottom-start"
                weekDayClassName={() => "text-sm text-gray-600 capitalize"}
                calendarClassName="bg-white shadow-md rounded p-2 !flex"
                locale="fi"
                dateFormat="dd.MM.yyyy HH:mm"
                showTimeSelect
                popperClassName="!z-10"
            />
        </div>
    );
};

export default CustomDatePicker;
