import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Spin, message as antdMessage } from "antd";
import api from "../../services/Api";
import { AuthContext } from "../../context/AuthContext";
import ChatMessages from "../../components/common/ChatMessages";
import ChatList from "../../components/common/ChatList";

const Chats = () => {
    const [chats, setChats] = useState([]);
    const [loading, setLoading] = useState(true);
    const { user } = useContext(AuthContext);
    const [selectedChat, setSelectedChat] = useState(null);
    const [replyContent, setReplyContent] = useState("");
    const { chatUuid } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        const fetchChats = async () => {
            try {
                const response = await api.getUserChats();
                setChats(response);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching chats:", error);
                antdMessage.error("Error fetching chats");
                setLoading(false);
            }
        };

        fetchChats();
    }, []);

    useEffect(() => {
        if (selectedChat) {
            const intervalId = setInterval(async () => {
                try {
                    const response = await api.getChatMessages(selectedChat.uuid);
                    setSelectedChat((prevChat) => ({
                        ...prevChat,
                        messages: response,
                    }));
                    scrollToMessageBottom();
                } catch (error) {
                    console.error("Error fetching messages:", error);
                    antdMessage.error("Error fetching messages");
                }
            }, 5000); // 5 seconds

            return () => clearInterval(intervalId);
        }
    }, [selectedChat]);

    useEffect(() => {
        if (chatUuid) {
            const chat = chats.find((chat) => chat.uuid === chatUuid);

            if (chat) {
                setSelectedChat(chat);
            }
        }
    }, [chatUuid, chats]);

    const fetchMessages = async (e) => {
        const chatUuid = e.currentTarget.dataset.id;

        try {
            const response = await api.getChatMessages(chatUuid);
            setSelectedChat({ ...selectedChat, messages: response, uuid: chatUuid });
        } catch (error) {
            console.error("Error fetching messages:", error);
            antdMessage.error("Error fetching messages");
        } finally {
            scrollToMessageBottom();
            navigate("/oma-sivu/viestit/" + chatUuid);
        }
    };

    const handleReplyChange = (e) => {
        setReplyContent(e.target.value);
    };

    const handleReplySubmit = async () => {
        if (!replyContent) {
            antdMessage.error("Message content cannot be empty");
            return;
        }

        const payload = {
            content: replyContent,
            chat_uuid: selectedChat.uuid,
        };

        try {
            const response = await api.sendChatMessage(payload);
            setSelectedChat((prevChat) => ({
                ...prevChat,
                messages: [...prevChat.messages, response],
            }));
            setReplyContent("");
            scrollToMessageBottom();
            antdMessage.success("Message sent");
        } catch (error) {
            console.error("Error sending message:", error);
            antdMessage.error("Error sending message");
        }
    };

    if (loading) {
        return <Spin />;
    }

    const scrollToMessageBottom = () => {
        const messages = document.getElementById("messages");

        if (messages) {
            messages.scrollTop = messages.scrollHeight;
        }
    };

    return (
        <div className="container max-w-screen-2xl m-auto text-lg">
            <h1 className="text-2xl mb-5">Viestit</h1>

            <div className="flex">
                <div id="chats" className="text-base w-1/4">
                    <ChatList chats={chats} fetchMessages={fetchMessages} />                    
                </div>
                <div className="border-l ml-5 pl-5 flex-1">
                    {selectedChat && (
                        <ChatMessages
                            selectedChat={selectedChat}
                            user={user}
                            replyContent={replyContent}
                            handleReplyChange={handleReplyChange}
                            handleReplySubmit={handleReplySubmit}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Chats;
