import React, { useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import RegisterForm from "../components/forms/RegisterForm";
import LoginForm from "../components/forms/LoginForm";
import { useLocation, useNavigate } from "react-router-dom";

const Login = () => {
    const { isAuthenticated, setReferer } = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();
    const redirectUrl = location.state?.from || null;

    useEffect(() => {
        if (isAuthenticated && redirectUrl) {
            navigate(redirectUrl, { replace: true });
        } else {
            setReferer(redirectUrl);
        }
    }, [isAuthenticated, navigate, redirectUrl, setReferer]);

    return (
        <div className="container m-auto max-w-screen-2xl">
            <div className="bg-[#b3d2d4] p-10 md:flex gap-10 rounded">
                <div>
                    <h2 className="text-2xl font-bold mb-5">Rekisteröidy</h2>
                    <RegisterForm />
                </div>
                <div>
                    <h2 className="text-2xl font-bold mb-5">Kirjaudu sisään</h2>
                    <p className="mb-5">Jos sinulla on jo tunnus voit kirjautua sisään tästä.</p>
                    <LoginForm />
                </div>
            </div>
        </div>
    );
};

export default Login;
