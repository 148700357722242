import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Button, Select, Tooltip } from "antd";
import { AuthContext } from "../../context/AuthContext";
import GalleryModal from "../modals/GalleryModal";
import SortableList, { SortableItem } from "react-easy-sort";
import arrayMove from "array-move";
import PropertyApartmentList from "./PropertyApartmentList";
import CKEditorWrapper from "../common/CKEditorWrapper";

const PropertyForm = ({
    initialValues,
    onFinish,
    loading,
    galleryModalOpen,
    setGalleryModalOpen,
    selectedImages,
    handleImageSelection,
}) => {
    const [description, setDescription] = useState(initialValues?.description || "");
    const { user, hasRole } = useContext(AuthContext);

    useEffect(() => {
        if (initialValues) {
            setDescription(initialValues.description || "");
        }
    }, [initialValues]);

    const handleEditorChange = (content) => {
        setDescription(content);
    };

    const handleSubmit = (values) => {
        onFinish({ ...values, description, images: selectedImages });
    };

    const fieldsDisabled =
        !initialValues || initialValues?.userProperty || hasRole(user.roles, "ROLE_SUPERADMIN") ? false : true;

    const onSortEnd = (oldIndex, newIndex) => {
        handleImageSelection(arrayMove([...selectedImages], oldIndex, newIndex));
    };

    useEffect(() => {
        selectedImages.forEach((image, index) => {
            image.sort_num = index + 1;
        });
    }, [selectedImages]);

    return (
        <Form layout="vertical" initialValues={initialValues} onFinish={handleSubmit}>
            <div className="flex justify-start gap-5">
                <Form.Item
                    label="Nimi"
                    name="title"
                    rules={[{ required: true, message: "Anna kohteelle nimi" }]}
                    className="flex-1"
                >
                    <Input disabled={fieldsDisabled} />
                </Form.Item>
                <Form.Item
                    label="Paikka"
                    name="area"
                    rules={[{ required: true, message: "Anna kohteelle paikka" }]}
                    className="flex-1"
                >
                    <Input disabled={fieldsDisabled} />
                </Form.Item>
                <Form.Item label="Omistusmuoto" name="ownershipType" className="flex-1">
                    <Select>
                        <Select.Option value="week">Viikko-osake</Select.Option>
                        <Select.Option value="1/4">1/4 osuus</Select.Option>
                        <Select.Option value="1/6">1/6 osuus</Select.Option>
                        <Select.Option value="1/8">1/8 osuus</Select.Option>
                        <Select.Option value="1/10">1/10 osuus</Select.Option>
                    </Select>
                </Form.Item>
            </div>
            <Form.Item label="Kuvaus" name="description">
                <CKEditorWrapper content={description} setContent={handleEditorChange} disabled={fieldsDisabled} />
            </Form.Item>
            <div className="flex gap-5">
                <div>
                    <div className="text-lg mb-5">Kohteen osoitetiedot</div>
                    <Form.Item label="Osoite" name="address">
                        <Input disabled={fieldsDisabled} />
                    </Form.Item>
                    <div className="flex gap-5">
                        <Form.Item label="Postinumero" name="zipcode" className="w-1/3">
                            <Input disabled={fieldsDisabled} />
                        </Form.Item>
                        <Form.Item label="Kaupunki" name="city">
                            <Input disabled={fieldsDisabled} />
                        </Form.Item>
                        <Form.Item label="Maa" name="country">
                            <Input disabled={fieldsDisabled} />
                        </Form.Item>
                    </div>
                </div>
                <div>
                    <div className="text-lg mb-5">Avainpalvelun osoitetiedot</div>
                    <Form.Item label="Osoite" name="serviceAddress">
                        <Input disabled={fieldsDisabled} />
                    </Form.Item>
                    <div className="flex gap-5">
                        <Form.Item label="Postinumero" name="serviceZipcode" className="w-1/3">
                            <Input disabled={fieldsDisabled} />
                        </Form.Item>
                        <Form.Item label="Kaupunki" name="serviceCity">
                            <Input disabled={fieldsDisabled} />
                        </Form.Item>
                        <Form.Item label="Maa" name="serviceCountry">
                            <Input disabled={fieldsDisabled} />
                        </Form.Item>
                    </div>
                </div>
            </div>
            <Form.Item label="Kohteen kuvat">
                <Button type="primary" onClick={() => setGalleryModalOpen(true)}>
                    Valitse kuvat
                </Button>
                <div>
                    <SortableList
                        onSortEnd={onSortEnd}
                        className="list flex flex-wrap gap-5 mt-5 user-select-none"
                        draggedItemClassName="dragged"
                    >
                        {selectedImages?.map((image) => (
                            <SortableItem key={image.id}>
                                <div
                                    style={{
                                        width: "175px",
                                        height: "125px",
                                        overflow: "hidden",
                                        border: "solid 1px #ddd",
                                        userSelect: "none",
                                        cursor: "grab",
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        backgroundImage: `url(${image.urls.thumbnail})`,
                                        position: "relative",
                                    }}
                                >
                                    <div>
                                        <Tooltip title="Poista kuva">
                                            <div
                                                onClick={() =>
                                                    handleImageSelection(
                                                        selectedImages.filter((img) => img.id !== image.id)
                                                    )
                                                }
                                                className="absolute top-1 right-1 bg-red-600 text-white p-1 rounded cursor-pointer"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="1.5"
                                                    stroke="currentColor"
                                                    className="size-4"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M6 18 18 6M6 6l12 12"
                                                    />
                                                </svg>
                                            </div>
                                        </Tooltip>
                                    </div>
                                </div>
                            </SortableItem>
                        ))}
                    </SortableList>
                </div>
            </Form.Item>
            {initialValues && (
                <div className="mt-5">
                    <h3 className="text-lg font-bold">Huoneistot</h3>
                    <PropertyApartmentList propertyId={initialValues?.id} />
                </div>
            )}
            <Form.Item className="text-right flex justify-end mt-5">
                <Button type="primary" htmlType="submit" loading={loading}>
                    Tallenna
                </Button>
            </Form.Item>
            <GalleryModal
                open={galleryModalOpen}
                onClose={() => setGalleryModalOpen(false)}
                onSelectImages={handleImageSelection}
                selectedImages={selectedImages}
            />
        </Form>
    );
};

export default PropertyForm;
