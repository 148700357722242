import React, { useContext, useState } from "react";
import { Modal, Tabs } from "antd";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import LoginForm from "../forms/LoginForm";
import RegisterForm from "../forms/RegisterForm";

const { TabPane } = Tabs;

const LoginModal = () => {
    const { isLoginModalOpen, hideLoginModal } = useContext(ModalContext);
    const [activeTab, setActiveTab] = useState("login");

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    return (
        <Modal
            title={activeTab === "login" ? "Kirjaudu" : "Rekisteröidy"}
            open={isLoginModalOpen}
            onCancel={hideLoginModal}
            footer={null}
            width={700}
        >
            <Tabs activeKey={activeTab} onChange={handleTabChange}>
                <TabPane tab="Kirjaudu" key="login">
                    <LoginForm onLoginSuccess={hideLoginModal} />
                </TabPane>
                <TabPane tab="Rekisteröidy" key="register">
                    <RegisterForm onRegisterSuccess={hideLoginModal} />
                </TabPane>
            </Tabs>
        </Modal>
    );
};

export default LoginModal;
