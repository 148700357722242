import React, { createContext, useState } from 'react';

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
    const [isLoginModalOpen, setLoginModalOpen] = useState(false);

    const showLoginModal = () => setLoginModalOpen(true);
    const hideLoginModal = () => setLoginModalOpen(false);

    return (
        <ModalContext.Provider value={{ isLoginModalOpen, showLoginModal, hideLoginModal }}>
            {children}
        </ModalContext.Provider>
    );
};
