import React from "react";
import { Form, InputNumber, Radio, Checkbox } from "antd";

import CustomDatePicker from "../../common/CustomDatePicker";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import CKEditorWrapper from "../../common/CKEditorWrapper";
import InfoBar from "../../common/InfoBar";

// Extend dayjs with timezone plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const AuctionFormSaleInfo = ({ auction, setAuction, handleDateChange, setStartNow }) => {
    const handleEditorChange = (content) => {
        setAuction({ ...auction, description: content });
    };

    return (
        <>
            <div className="text-xl font-bold mb-2">Ilmoituksen tiedot</div>

            {auction.auctionType === "rent" && (
                <Form.Item
                    label="Vuokrahinta"
                    name="startPrice"
                    rules={[
                        {
                            required: true,
                            message: "Anna vuokrahinta",
                        },
                    ]}
                >
                    <InputNumber
                        addonAfter="€ / vko"
                        value={auction.startPrice}
                        onChange={(value) => setAuction({ ...auction, startPrice: value })}
                        disabled={
                            auction.status === "draft" || (auction.status === "" && auction.type === undefined)
                                ? false
                                : true
                        }
                    />
                </Form.Item>
            )}
            {auction.auctionType === "sale" && (
                <Form.Item
                    label="Myyntihinta"
                    name="startPrice"
                    rules={[{ required: true, message: "Anna myyntihinta" }]}
                >
                    <InputNumber
                        value={auction.startPrice}
                        onChange={(value) => setAuction({ ...auction, startPrice: value })}
                        addonAfter="€"
                        disabled={
                            auction.status === "draft" || (auction.status === "" && auction.type === undefined)
                                ? false
                                : true
                        }
                    />
                </Form.Item>
            )}
            {auction.auctionType === "auction" && (
                <div className="grid grid-cols-3 gap-5">
                    <div>
                        <Form.Item name="startPrice" label="Lähtöhinta" required={true} className="m-0">
                            <InputNumber
                                value={auction.startPrice}
                                onChange={(value) => setAuction({ ...auction, startPrice: value })}
                                style={{ width: "100%" }}
                                className="mb-5"
                                addonAfter="€"
                                disabled={
                                    auction.status === "draft" || (auction.status === "" && auction.type === undefined)
                                        ? false
                                        : true
                                }
                            />
                        </Form.Item>
                        <Form.Item>
                            <Checkbox
                                name="minSalePrice"
                                onChange={(e) =>
                                    setAuction({
                                        ...auction,
                                        minSalePrice: e.target.checked ? 1 : 0,
                                    })
                                }
                                checked={auction.minSalePrice > 0 ? true : false}
                                required={true}
                            >
                                Sitoudun myymään eniten tarjoavalle
                            </Checkbox>
                        </Form.Item>
                    </div>
                    <Form.Item name="minBid" label="Minimikorotus" required={true}>
                        <InputNumber
                            value={auction.minBid}
                            onChange={(value) => setAuction({ ...auction, minBid: value })}
                            style={{ width: "100%" }}
                            addonAfter="€"
                            disabled={
                                auction.status === "draft" || (auction.status === "" && auction.type === undefined)
                                    ? false
                                    : true
                            }
                        />
                    </Form.Item>
                </div>
            )}

            {auction.auctionType === "auction" && (
                <div className="grid grid-cols-3 gap-5">
                    <div className="mb-5">
                        <Form.Item name="starts" label="Myynti alkaa" className="mb-2">
                            <CustomDatePicker
                                selectedDate={auction.starts ? dayjs(auction.starts).toDate() : null}
                                onDateChange={(date) => setAuction({ ...auction, starts: dayjs(date).toISOString() })}
                            />
                        </Form.Item>
                        {auction.starts === null && (
                            <div className="underline text-primary cursor-pointer" onClick={setStartNow}>
                                Aloita heti
                            </div>
                        )}
                    </div>
                    <div>
                        <Form.Item name="ends" label="Myynti päättyy">
                            <CustomDatePicker
                                selectedDate={auction.ends ? dayjs(auction.ends).toDate() : null}
                                onDateChange={(date) => setAuction({ ...auction, ends: dayjs(date).toISOString() })}
                            />
                        </Form.Item>
                    </div>
                </div>
            )}

            {auction.auctionType === "sale" && (
                <Form.Item label="Myyntiaika" name="saleTime" required={true}>
                    <Radio.Group
                        disabled={
                            auction.status === "draft" || (auction.status === "" && auction.type === undefined)
                                ? false
                                : true
                        }
                        name="saleTime"
                        value={auction.saleTime}
                        onChange={(e) => setAuction({ ...auction, saleTime: e.target.value })}
                        optionType="button"
                        buttonStyle="solid"
                    >
                        <Radio value={6}>6 kk</Radio>
                        <Radio value={12}>12 kk</Radio>
                    </Radio.Group>
                </Form.Item>
            )}

            <Form.Item label="Ilmoituksen teksti" name="description">
                {auction.property.description && (
                    <InfoBar>
                        Valitsemallasi kohteella on valmis kuvaus, jonka näet kohteen tiedoissa ylempänä.
                        Täydennä ilmoituksen tekstiä tarvittaessa.
                    </InfoBar>
                )}
                <CKEditorWrapper content={auction.description} setContent={handleEditorChange} />
            </Form.Item>
        </>
    );
};

export default AuctionFormSaleInfo;
