import React, { useState, useEffect } from "react";
import { Image, Spin } from "antd";
import { PlayCircleFilled, CaretLeftFilled } from "@ant-design/icons";

const AuctionGallery = ({ images }) => {
    const [currentImage, setCurrentImage] = useState(images[0]);

    useEffect(() => {
        if (images && images.length > 0) {
            setCurrentImage(images[0]);
        }
    }, [images]);

    if (!images || images.length === 0) {
        return <p>No images available</p>;
    }

    return (
        <>
            <div className="flex flex-col items-center">
                {currentImage && (
                    <div
                        className="h-[250px] md:h-[700px] relative"
                        style={{
                            backgroundImage: `url(${currentImage.urls?.original})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            width: "100%",
                            borderRadius: "5px",
                        }}
                    >
                        {currentImage.sort_num > 1 && (
                            <div className="absolute top-0 left-5 h-full flex items-center justify-center text-5xl text-white">
                                <PlayCircleFilled
                                    className="opacity-70 cursor-pointer transform rotate-180"
                                    onClick={() => setCurrentImage(images[currentImage.sort_num - 2])}
                                />
                            </div>
                        )}
                        {currentImage.sort_num < images.length && (
                            <div className="absolute top-0 right-5 h-full flex items-center justify-center text-5xl text-white">
                                <PlayCircleFilled
                                    className="opacity-70 cursor-pointer"
                                    onClick={() => setCurrentImage(images[currentImage.sort_num])}
                                />
                            </div>
                        )}
                    </div>
                )}
                <div
                    id="tn_holder"
                    className="w-full overflow-x-auto overflow-y-hidden whitespace-nowrap mb-10 relative scrollbar scrollbar-thumb-primary-100 scrollbar-track-primary-500 my-3"
                >
                    {images.map((image) => (
                        <div key={image.id} className="inline-block relative">
                            {currentImage && currentImage.id !== image.id && (
                                <div
                                    className="bg-white bg-opacity-30 absolute w-full h-full z-20"
                                    onClick={() => setCurrentImage(image)}
                                ></div>
                            )}
                            <Image
                                src={image.urls.thumbnail}
                                preview={false}
                                style={{
                                    height: "125px",
                                    width: "175px",
                                    cursor: "pointer",
                                    marginRight: "10px",
                                    borderRadius: "6px",
                                }}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default AuctionGallery;
