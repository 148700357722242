import React from "react";

import { Link } from "react-router-dom";
import { Drawer } from "antd";
import AuctionCountdownTimer from "./AuctionCountdownTimer";

const FavoritesDrawer = ({ open, onClose, favorites, loading }) => {
    return (
        <Drawer title="Suosikit" onClose={onClose} open={open} loading={loading}>
            {favorites?.map((auction) => (
                <Link key={auction.number} to={`/kohde/${auction.number}`}>
                    <div className="text-gray-800 flex items-start mb-2 opacity-80 hover:opacity-100 transition-all transition-duration-100">
                        <div
                            className="flex-shrink-0 rounded-sm"
                            style={{
                                backgroundImage: `url(${auction.images[0].urls.medium})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                height: "40px",
                                width: "60px",
                            }}
                        ></div>
                        <div className="pl-3 flex-1 text-xs">
                            <div className="text-primary text-sm font-semibold truncate">{auction.title}</div>
                            <div className="">
                                {auction.ownershipType === "week" ? (
                                    <>Vk {auction.propertyWeek}</>
                                ) : (
                                    <>{auction.ownershipType}</>
                                )}
                            </div>
                            <div>
                                {auction.auctionType === "auction" &&
                                    `Korkein tarjous: ${auction.currentPrice} € (${auction.bidCount} tarjousta)`}
                                {auction.auctionType === "sale" && `Hinta: ${auction.startPrice} €`}
                                {auction.auctionType === "rent" && `Hinta: ${auction.startPrice} € / vko`}
                            </div>
                            <div>
                                <span className="mr-1">Päättyy:</span>
                                <strong>
                                    <AuctionCountdownTimer auction={auction} />
                                </strong>
                            </div>
                        </div>
                    </div>
                </Link>
            ))}
        </Drawer>
    );
};

export default FavoritesDrawer;
