import React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Breadcrumbs from "../common/Breadcrumbs";
import Sidebar from "../common/Sidebar";

const AdminLayout = ({ children }) => {
    return (
        <div className="admin-layout">
            <Header pageType={1} />
            <Breadcrumbs />
            <main>
                <div className="container m-auto max-w-7xl flex">
                    <div className="w-1/6 mr-5">
                        <Sidebar />
                    </div>
                    <div className="w-5/6">{children}</div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default AdminLayout;
