import React, { useEffect, useState } from "react";
import api from "../../services/Api"; // Import your API service

const AuctionCountdownTimer = ({ auction }) => {
    const [timeLeft, setTimeLeft] = useState("");

    useEffect(() => {
        if (auction && auction.ends) {
            const interval = setInterval(() => {
                const now = new Date();
                const endDate = new Date(auction.ends.date);
                const timeDiff = endDate - now;

                if (timeDiff <= 0) {
                    clearInterval(interval);
                    setTimeLeft("Päättynyt");

                    // Update the auction status to expired
                    if (auction.status !== "expired") {
                        // Set the auction status to expired locally
                        auction.status = "expired";

                        // Call API to set the auction as expired
                        setAuctionExpired(auction.id);
                    }
                } else {
                    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
                    const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
                    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

                    if (days > 0) {
                        setTimeLeft(`${days} pv ${hours} t`);
                    } else if (hours > 0) {
                        setTimeLeft(`${hours} t ${minutes} min`);
                    } else {
                        setTimeLeft(`${minutes} min ${seconds} s`);
                    }
                }
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [auction]);

    const setAuctionExpired = async (auctionId) => {
        try {
            await api.setAuctionExpired(auctionId); 
        } catch (error) {
            console.error(`Failed to set auction ${auctionId} as expired:`, error);
        }
    };

    return <>{timeLeft}</>;
};

export default AuctionCountdownTimer;
