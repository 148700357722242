import React, { useEffect, useState } from "react";

import api from "../../services/Api";
import { Table } from "antd";

const Users = () => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await api.getUsers();
                setUsers(response);
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        };

        fetchUsers();
    }, []);

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Sähköposti",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Roolit",
            dataIndex: "roles",
            key: "roles",
            render: (roles) => roles.map((role) => role).join(", "),
        },
        {
            title: "Uuid",
            dataIndex: "uuid",
            key: "uuid",
        }
    ];

    return (
        <div className="container max-w-screen-2xl m-auto text-lg">
            <h1 className="text-2xl mb-5">Käyttäjät</h1>

            <Table dataSource={users} columns={columns} rowKey="id" size="small" />
        </div>
    );
}

export default Users;