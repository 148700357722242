import React from "react";
import { Link } from "react-router-dom";
import { ExportOutlined } from "@ant-design/icons";

const URL =
    process.env.NODE_ENV === "development" ? "http://api-lomahuuto.localhost" : "https://api-lomahuuto.mkoskinen.net";

const AuctionDetails = ({ auction }) => {
    const ownershipStrings = {
        "1/4": "joka neljäs viikko, katso viikkoliite",
        "1/6": "joka kuudes viikko, katso viikkoliite",
        "1/8": "joka kahdeksas viikko, katso viikkoliite",
        "1/10": "joka kymmenes viikko, katso viikkoliite",
    };

    return (
        <>
            <h3>Kohteen tiedot</h3>
            {auction.property?.address && (
                <>
                    <p className="mb-5">
                        <strong>Kohteen osoite</strong>
                        <br />
                        {auction.property?.address}, {auction.property?.zipcode} {auction.property?.city}
                    </p>
                    {auction.apartmentDescription && (
                        <>
                            <p className="mb-0">Huoneistokuvaus: {auction.apartmentDescription}</p>
                        </>
                    )}
                    {auction.roomNumber && (
                        <>
                            <p className="mb-0">Huoneiston tunnus: {auction.roomNumber}</p>
                        </>
                    )}
                    <p className="mb-0">
                        Omistusmuoto:{" "}
                        {auction.property.ownershipType === "week"
                            ? "viikko-osake"
                            : auction.property.ownershipType + " osaomistus"}
                    </p>
                    {auction.maintenanceCharge && auction.auctionType !== "rent" && (
                        <>
                            <p className="mb-0">
                                Viimeisin vahvistettu vastikkeen määrä: {auction.maintenanceCharge} &euro;
                            </p>
                            <p>
                                Vastikkeen maksuväli: {auction.property.ownershipType === "week" ? "vuosi" : "kuukausi"}
                            </p>
                        </>
                    )}
                </>
            )}

            {auction.property?.serviceAddress && (
                <p className="mb-5">
                    <strong>Avainpalvelun osoite</strong>
                    <br />
                    {auction.property?.serviceAddress}, {auction.property?.serviceZipcode}{" "}
                    {auction.property?.serviceCity}
                </p>
            )}

            {auction.property?.website && (
                <>
                    <div>
                        <strong>Linkki kohteen sivuille</strong>
                    </div>
                    <Link target="_blank" to={auction.property?.website} className="text-blue-600">
                        <ExportOutlined className="mr-2" />
                        {auction.property?.website}
                    </Link>
                </>
            )}

            {auction.propertyWeek && (
                <div className="mt-5">
                    <h3>
                        {auction.type !== "rent" ? (
                            <>Seuraava huoneiston käyttöoikeus</>
                        ) : (
                            <>Huoneisto vuokrataan ajalle</>
                        )}
                    </h3>
                    <p>
                        {auction.property.ownershipType === "week" ? (
                            <>
                                Viikko {auction.propertyWeek} / {auction.propertyWeekDates}
                            </>
                        ) : (
                            <div className="first-letter:capitalize">
                                {ownershipStrings[auction.property.ownershipType]}
                            </div>
                        )}
                    </p>
                </div>
            )}

            {auction.property?.description && (
                <>
                    <h3 className="mt-5">Kohde-esittely</h3>
                    <div className="text-left" dangerouslySetInnerHTML={{ __html: auction.property.description }} />
                </>
            )}

            {auction.description && (
                <>
                    <h3 className="mt-5">Ilmoitusteksti</h3>
                    <div className="text-left" dangerouslySetInnerHTML={{ __html: auction.description }} />
                </>
            )}

            {auction.files.length > 0 && (
                <>
                    <h3 className="mt-5">Tiedostot</h3>
                    {auction.files.map((file, index) => (
                        <div key={index} className="flex items-center">
                            <div className="mr-3">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="size-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                                    />
                                </svg>
                            </div>
                            <div>
                                <Link
                                    to={URL + "/lataa/" + file.id}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="underline text-blue-900"
                                >
                                    {file.description}
                                </Link>
                            </div>
                        </div>
                    ))}
                </>
            )}

            {auction.links.length > 0 && (
                <>
                    <h3 className="mt-5">3D- tai videoesittelyt</h3>
                    {auction.links.map((link, index) => (
                        <div key={link.id} className="flex items-center">
                            <div className="mr-3">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="size-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                                    />
                                </svg>
                            </div>
                            <Link to={`https://${link.url}`} target="_blank" className="underline text-blue-900">
                                {link.description ? link.description : `Esittely #${index + 1}`}
                            </Link>
                        </div>
                    ))}
                </>
            )}

            {/* {auction.mandator ? (
                <>
                    <h3 className="mt-5">Toimeksiantaja</h3>
                    <div className="grid gap-2" style={{ gridTemplateColumns: 'min-content auto' }}>
                        <div className="w-36">
                            <strong>Nimi:</strong>
                        </div>
                        <div>{auction.mandator.name}</div>
                        {auction.mandator.businessId && (
                            <>
                                <div>
                                    <strong>Y-tunnus:</strong>
                                </div>
                                <div>{auction.mandator.businessId}</div>
                            </>
                        )}
                        <div>
                            <strong>Osoite:</strong>
                        </div>
                        <div>{auction.mandator.address}</div>
                        <div>
                            <strong>Puhelin:</strong>
                        </div>
                        <div>{auction.mandator.phonenumber}</div>
                        <div>
                            <strong>Sähköposti:</strong>
                        </div>
                        <div>{auction.mandator.email}</div>
                    </div>
                </>
            ) : null} */}
        </>
    );
};

export default AuctionDetails;
