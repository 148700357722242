import React, { useState, useEffect, useCallback } from "react";
import api from "../../../services/Api";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Button, message, Spin, Form, Radio, Modal, Checkbox } from "antd";

import PreviewModal from "../../modals/PreviewModal";
import SettingsModal from "../../modals/SettingsModal";
import PropertySelectModal from "../../modals/PropertySelectModal";

import AuctionFormProperty from "./AuctionFormProperty";
import AuctionFormMandator from "./AuctionFormMandator";
import AuctionFormSaleInfo from "./AuctionFormSaleInfo";
import AuctionFormFiles from "./AuctionFormFiles";
import AuctionFormLinks from "./AuctionFormLinks";

const AuctionForm = () => {
    const [auction, setAuction] = useState({
        auctionType: "",
        startPrice: null,
        minBid: null,
        minSalePrice: null,
        status: "",
        property: null,
        propertyId: null,
        propertyWeek: null,
        propertyWeekStart: null,
        propertyAttributes: [],
        starts: null,
        ends: null,
        description: "",
        saleTime: null,
        roomNumber: "",
        maintenanceCharge: null,
        images: [],
        files: [],
        links: [],
        mandator: {},
        hasMandator: false,
    });
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [propertyModalOpen, setPropertyModalOpen] = useState(false);
    const navigate = useNavigate();
    const { uuid } = useParams();
    const [form] = Form.useForm();
    const [termsModalOpen, setTermsModalOpen] = useState(false);
    const [previewModalOpen, setPreviewModalOpen] = useState(null);

    const showTermsModal = () => {
        setTermsModalOpen(true);
    };

    const closeTermsModal = () => {
        setTermsModalOpen(false);
    };

    useEffect(() => {
        if (uuid && uuid !== "uusi") {
            setIsLoading(true);

            const fetchAuction = async () => {
                try {
                    const response = await api.getUserAuction(uuid);
                    setAuction(response);

                    if (response.mandator?.id) {
                        setAuction((prevState) => ({
                            ...prevState,
                            hasMandator: true,
                            mandator: {
                                id: response.mandator.id,
                                type: response.mandator.type,
                                name: response.mandator.name,
                                address: response.mandator.address,
                                phonenumber: response.mandator.phonenumber,
                                email: response.mandator.email,
                                businessId: response.mandator.businessId,
                            },
                        }));
                    }

                    form.setFieldsValue(response);
                    setIsLoading(false);
                } catch (error) {
                    setIsLoading(false);
                }
            };
            fetchAuction();
        }
    }, [uuid]);

    const handleSelectAuctionType = async (event) => {
        const auctionType = event.target.value;

        setAuction((prevAuction) => ({
            ...prevAuction,
            auctionType: auctionType,
        }));

        if (uuid === "uusi") {
            try {
                const response = await api.createAuction({ auctionType: auctionType });
                navigate(`/oma-sivu/ilmoitukset/${response.uuid}`);
            } catch (error) {
                console.error("Error creating auction:", error);
            }
        }
    };

    const handleSave = async () => {
        setIsSaving(true);

        try {
            await api.saveAuction(uuid, {
                ...auction,
                starts: auction.starts ? auction.starts : null,
                ends: auction.ends ? auction.ends : null,
            });

            setIsSaving(false);

            message.success(auction.status === "draft" ? "Luonnos tallennettu" : "Ilmoitus tallennettu");
        } catch (error) {
            setIsSaving(false);
            message.error("Virhe ilmoituksen tallentamisessa");
        }
    };

    const handleFilesChange = (updatedFiles) => {
        setAuction((prevAuction) => ({
            ...prevAuction,
            files: updatedFiles,
        }));
    };

    const handleStatusChange = (status) => {
        var modalTitle = "";
        var modalContent = "";
        var modalSuccess = "";

        if (status === "published") {
            modalTitle = "Julkaise ilmoitus";
            modalContent = "Olet julkaisemassa ilmoitusta. Oletko varma?";
            modalSuccess = "Ilmoitus julkaistu";

            if (!validateForm(form)) {
                return;
            }
        } else if (status === "sold") {
            modalTitle = "Merkitse myydyksi";
            modalContent = "Olet merkitsemässä kohdetta myydyksi. Oletko varma?";
            modalSuccess = "Kohde merkitty myydyksi";
        } else if (status === "cancelled") {
            modalTitle = "Lopeta julkaisu";
            modalContent = "Olet lopettamassa ilmoituksen julkaisua. Oletko varma?";
            modalSuccess = "Ilmoitus lopetettu";
        }

        Modal.confirm({
            title: modalTitle,
            content: modalContent,
            onOk: async () => {
                setIsSaving(true);

                setAuction((prevAuction) => ({
                    ...prevAuction,
                    status: status,
                }));

                if (status === "published") {
                    try {
                        await api.saveAuction(uuid, { ...auction, status: status });
                        await api.publishAuction(uuid);
                        setIsSaving(false);
                        message.success(modalSuccess);
                    } catch (error) {
                        console.log(error);
                    } finally {
                        setIsSaving(false);
                    }
                } else {
                    try {
                        await api.saveAuction(uuid, { ...auction, status: status });
                        setIsSaving(false);
                        message.success(modalSuccess);
                    } catch (error) {
                        console.log(error);
                    } finally {
                        setIsSaving(false);
                    }
                }
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const handlePropertySelect = (property) => {
        setAuction((prevAuction) => ({
            ...prevAuction,
            propertyId: property.id,
            property: property,
            images: property.images,
        }));
    };

    const statusColors = ["#f9c826", "#abe5aa", "#d0021b", "#b0b0b0", "#ff8888", "#cccccc"];
    const statusNames = ["draft", "published", "sold", "expired", "cancelled", "expired"];
    const statusLabels = {};

    statusNames.forEach((name, index) => {
        statusLabels[name] = statusColors[index];
    });

    const setStartNow = () => {
        setAuction({ ...auction, starts: new Date() });
    };

    const validateForm = (form) => {
        form.setFields(
            form.getFieldsError().map(({ name }) => ({
                name,
                errors: [],
            }))
        );

        const errors = [];
        const errorDiv = document.querySelectorAll(".formError");

        errorDiv.forEach((div) => {
            div.classList.add("hidden");
        });

        const requiredFields = [
            ["", "property", "Kohde on pakollinen"],
            ["", "roomNumber", "Huoneisto on pakollinen"],
            ["", "propertyWeekStart", "Valitse seuraava huoneiston käyttöoikeusviikko"],
            ["", "startPrice", "Hinta on pakollinen"],
            ["auction", "minBid", "Minimikorotus on pakollinen"],
            ["auction", "minSalePrice", "Alin myyntihinta on pakollinen"],
            ["sale", "saleTime", "Myyntiaika on pakollinen"],
            ["", "terms[]", "Ilmoitusehdot on hyväksyttävä"],
        ];

        requiredFields.forEach((field) => {
            if ((field[0] === auction.auctionType || field[0] === "") && field[1] !== "terms[]" && auction[field[1]] === null) {
                errors.push({
                    name: field[1],
                    error: field[2],
                });                
            }

            // Check if all terms[] fields are checked
            if (field[1] === "terms[]") {
                const terms = document.querySelectorAll("input[name='terms[]']");
                let checked = 0;

                terms.forEach((term) => {
                    if (term.checked) {
                        checked++;
                    }
                });

                if (checked < terms.length) {
                    errors.push({
                        name: field[1],
                        error: field[2],
                    });
                }
            }
        });

        setIsSaving(false);

        if (errors.length > 0) {
            errorDiv.forEach((div) => {
                div.innerHTML = errors.map((error) => `<div>${error.error}</div>`).join("");
                div.classList.remove("hidden");
            });

            form.setFields(errors);

            return false;
        }

        return true;
    };

    useEffect(() => {
        auction.images.forEach((image, index) => {
            image.sort_num = index + 1;
        });
    }, [auction.images]);

    const handleLinksChange = (newLinks) => {
        setAuction((prevAuction) => ({
            ...prevAuction,
            links: newLinks,
        }));
    };

    const handleMandatorChange = useCallback((mandatorData) => {
        setAuction((prevAuction) => ({
            ...prevAuction,
            mandator: mandatorData.mandator,
            hasMandator: mandatorData.isMandatorChecked,
        }));
    }, []);

    return (
        <div className="auction-form">
            <Spin spinning={isSaving} tip="Tallennetaan...">
                <Spin spinning={isLoading} tip="Ladataan...">
                    <h1 className="text-2xl font-bold mb-5 flex justify-between">
                        {auction.status ? "Muokkaa ilmoitusta " : "Uusi ilmoitus"}
                        {auction.number && <Link to={`/kohde/${auction.number}`} target="_blank">#{auction.number}</Link>}
                    </h1>

                    <div
                        style={{ backgroundColor: `${statusLabels[auction.status]}` }}
                        className="flex justify-between items-center p-4 mb-5 rounded"
                    >
                        <div className="text-xl">
                            {auction.status === "draft" && <>Ilmoitus on luonnos.</>}
                            {auction.status === "published" && <>Ilmoitus on julkaistu.</>}
                            {auction.status === "sold" && <>Kohde on myyty.</>}
                            {auction.status === "cancelled" && <>Ilmoitus on poistettu.</>}
                            {auction.status === "expired" && auction.auctionType === "auction" && (
                                <>Huutokauppa on päättynyt.</>
                            )}
                            {auction.status === "expired" && auction.auctionType !== "auction" && (
                                <>Ilmoitus on vanhentunut.</>
                            )}
                        </div>
                        {auction.status === "draft" && (
                            <>
                                <div
                                    className="px-4 py-2 rounded inline-block text-lg cursor-pointer bg-emerald-500 hover:bg-emerald-400 transition-colors text-white font-bold"
                                    onClick={() => handleStatusChange("published")}
                                >
                                    Julkaise ilmoitus
                                </div>
                            </>
                        )}
                        {auction.status === "published" && (
                            <>
                                <div>
                                    <div
                                        className="px-4 py-2 rounded inline-block text-lg cursor-pointer bg-emerald-500 hover:bg-emerald-400 transition-colors text-white font-bold mr-5"
                                        onClick={() => handleStatusChange("sold")}
                                    >
                                        Merkitse myydyksi
                                    </div>
                                    <div
                                        className="px-4 py-2 rounded inline-block text-lg cursor-pointer bg-red-500 hover:bg-red-700 transition-colors text-white"
                                        onClick={() => handleStatusChange("cancelled")}
                                    >
                                        Lopeta julkaisu
                                    </div>
                                </div>
                            </>
                        )}
                        {auction.status === "cancelled" && (
                            <>
                                <div
                                    className="px-4 py-2 rounded inline-block text-lg cursor-pointer bg-yellow-500 hover:bg-emerald-400 transition-colors text-white font-bold"
                                    onClick={() => handleStatusChange("draft")}
                                >
                                    Merkitse luonnokseksi (vain kehitysvaiheessa)
                                </div>
                            </>
                        )}
                    </div>

                    <div className="formError text-white bg-red-600 px-3 py-2 mb-5 rounded hidden"></div>

                    <div className="bg-gray-100 rounded p-5 mb-5">
                        <Form layout="vertical" size="medium" onFinish={handleSave} form={form}>
                            {auction.type !== undefined && auction.status !== "draft" && (
                                <div className="mb-5 flex items-center bg-yellow-400 rounded p-2">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="size-6 mr-2"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                                        />
                                    </svg>
                                    Ilmoitus on julkaistu ja sitä ei voi muokata
                                </div>
                            )}
                            <div className="text-xl font-bold mb-2">Ilmoituksen tyyppi</div>
                            <Form.Item label="">
                                <Radio.Group
                                    name="auctionType"
                                    value={auction.auctionType}
                                    onChange={handleSelectAuctionType}
                                    buttonStyle="solid"
                                >
                                    <Radio.Button value="auction">Huutokauppa</Radio.Button>
                                    <Radio.Button value="sale">Myynti</Radio.Button>
                                    <Radio.Button value="rent">Vuokraus</Radio.Button>
                                </Radio.Group>
                            </Form.Item>

                            {auction.auctionType !== "" && (
                                <>
                                    <AuctionFormProperty
                                        auction={auction}
                                        setAuction={setAuction}
                                    />

                                    {auction.roomNumber && (
                                        <>
                                            <hr className="my-5 border-gray-300" />

                                            <AuctionFormSaleInfo
                                                auction={auction}
                                                setAuction={setAuction}
                                                setStartNow={setStartNow}
                                            />

                                            <hr className="my-5 border-gray-300" />

                                            <AuctionFormFiles
                                                initialFiles={auction.files}
                                                onFilesChange={handleFilesChange}
                                                auctionId={auction.uuid}
                                            />

                                            <AuctionFormLinks links={auction.links} onLinksChange={handleLinksChange} />

                                            <hr className="my-5 border-gray-300" />

                                            <AuctionFormMandator
                                                initialMandator={auction.mandator}
                                                onMandatorChange={handleMandatorChange}
                                            />

                                            <hr className="my-5 border-gray-300" />

                                            <div className="mt-5">
                                                <h2 className="text-xl font-bold mb-2">Ilmoitusehdot</h2>
                                                <Checkbox
                                                    className="terms mb-2 font-medium"
                                                    name="terms[]"
                                                    value="1"
                                                    required={true}
                                                >
                                                    Vahvistan, että ilmoituksessa olevat tiedot ovat oikeat ja että
                                                    ilmoittajana olen vastuussa kohteen tietojen oikeellisuudesta sekä
                                                    hyväksyn{" "}
                                                    <span className="text-blue-600 underline" onClick={showTermsModal}>
                                                        käyttöehdot
                                                    </span>
                                                    .
                                                </Checkbox>
                                            </div>
                                        </>
                                    )}

                                    <div className="formError text-white bg-red-600 px-3 py-2 my-5 mb-5 rounded hidden"></div>

                                    <div className="text-right flex justify-end mt-5">
                                        <Button
                                            type="button"
                                            onClick={() => setPreviewModalOpen(true)}
                                            className="bg-yellow-500 text-black hover:bg-yellow-400"
                                        >
                                            Esikatselu
                                        </Button>
                                        {auction.status === "draft" && (
                                            <Button
                                                type="success"
                                                className="bg-green-400 hover:bg-green-300 ml-5"
                                                onClick={() => handleStatusChange("published")}
                                            >
                                                Julkaise ilmoitus
                                            </Button>
                                        )}
                                        <Form.Item>
                                            <Button type="primary" onClick={handleSave} className="ml-5">
                                                Tallenna ilmoitus
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </>
                            )}
                        </Form>
                    </div>
                    <PropertySelectModal
                        open={propertyModalOpen}
                        onClose={() => setPropertyModalOpen(false)}
                        onSelectProperty={handlePropertySelect}
                    />
                    <SettingsModal
                        title="Käyttöehdot"
                        settingName="terms_of_use"
                        open={termsModalOpen}
                        onClose={closeTermsModal}
                        className="w-1/2"
                    />
                    <PreviewModal
                        title="Esikatselu"
                        open={previewModalOpen}
                        onClose={() => setPreviewModalOpen(false)}
                        auction={auction}
                    />
                </Spin>
            </Spin>
        </div>
    );
};

export default AuctionForm;
